import React from 'react';
import { Link, Alert, Button, IconButton, FormControl, Tooltip, Checkbox, Card } from '@mui/material';
import Out from '../../error/out';
import Swal from 'sweetalert2'
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


class Imagen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nextId: 0,
            imgs_seleccionadas: [],
            imagenes: [],
            id_product: window.location.pathname.split("/")[3],
            loading: false,
            error: false
        }
    }



    componentDidMount() {
        fetch(`${process.env.REACT_APP_API}limpiar`);
        fetch(`${process.env.REACT_APP_API}createFolder`);
        fetch(`${process.env.REACT_APP_API}imagenes/${this.state.id_product}`)
            .then(data => {
                return data.json();
            }).then(data => {
                this.setState({
                    imagenes: data.result,
                    nextId: data.result.length !== 0 ? Math.max(...data.result.map(p => p.id)) + 1 : 0
                });
            }).catch(erro => {
                this.setState({
                    error: true
                })
            })
    }


    delete(e, id) {

        Swal.fire({
            title: '¿ Deseas eliminar esta URL?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Borrar!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_API}delete/imagen/${id}`)
                    .then(data => {
                        if (data.status === 200) {
                            Swal.fire({
                                title: 'Borrado',
                                text: 'La URL se ha eliminado',
                                icon: 'success',
                                timer: 1500,
                                showConfirmButton: false,
                                timerProgressBar: true,
                                willClose: () => {
                                    window.location.reload();
                                }
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Hubo algun error con el servidor',
                            })
                        }
                    });
            }
        })
    }

    changePrincipal = (id, value) => {
        var principal = value === false ? 0 : 1;
        fetch(`${process.env.REACT_APP_API}imagen/reset/${this.state.id_product}`)
            .then(data => {
                return data.json();
            }).then(data => {
                fetch(`${process.env.REACT_APP_API}imagen/change/${id}/principal/${principal}`)
                    .then(data => {
                        return data.json();
                    }).then(data => {
                        window.location.reload();
                    }).catch(erro => {
                        this.setState({
                            error: true
                        })
                    })
            }).catch(erro => {
                this.setState({
                    error: true
                })
            })
    }

    render() {
        return (
            <>
                {this.state.loading
                    ? <Out loading={this.state.loading} error={this.state.error} />
                    : <>
                        <Alert severity="success" style={{ width: "50%", margin: "0px auto" }} className="mt-5">
                            Se esta Añadiendo imagen al producto
                            <br /><strong>
                                <Link to={`/adm/products`} color="success" underline="hover" component={RouterLink}>
                                    {` ${this.state.id_product}`}
                                </Link>
                            </strong>
                        </Alert>

                        <form action={`${process.env.REACT_APP_API}imagen/save`} method="post" encType="multipart/form-data">
                            <input id="prevPage" name="prevPage" type="hidden" value={window.location.href} />
                            <input id="id_product" name="id_product" type="hidden" value={this.state.id_product} />
                            <div style={{ width: "30%", margin: "0px auto", textAlign: "center" }} className="mt-5 row">

                                <FormControl fullWidth className="mt-5">
                                    <input type="file"
                                        className='btn'
                                        style={{ background: "#f3fbfd" }}
                                        multiple
                                        id="imagenes" name="imagenes"
                                        required
                                    />
                                </FormControl>
                                <Button className="mt-2" type="submit" variant="contained">Add</Button>
                            </div>
                        </form>

                        <div style={{ width: "80%", margin: "0px auto", textAlign: "center" }} className="mt-5 mb-5 row">
                            <div className='col-xs-12 col-sm-12 col-md-12' style={{ textAlign: "left" }}>
                                <Tooltip title="Volver">
                                    <Link to={`/adm/products`} style={{ color: "green" }} component={RouterLink}>
                                        <ArrowBackIcon />
                                    </Link>
                                </Tooltip>
                            </div>
                            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 row'>
                                {this.state.imagenes.map((imagen, i) => {
                                    return (
                                        <>
                                            <Card key={i} style={{ margin: "10px", padding: "1%" }}>
                                                <div className='row'>
                                                    <IconButton aria-label="delete" color="error" onClick={(e) => { this.delete(e, imagen.id) }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    <Checkbox checked={imagen.is_main === 1 ? true : false} onChange={(e, v) => { this.changePrincipal(imagen.id, v) }} />
                                                </div>

                                                <div  style={{ margin: "5px" }}>
                                                    <img src={imagen.url} width={200} height={200} alt={i} />
                                                </div>
                                            </Card>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </>}
            </>)
    }
}

export default Imagen;