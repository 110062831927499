




import { Box, Button, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import Alert from '@mui/material/Alert';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'

export default function Collection() {
    const [loading, setLoading] = useState(false);
    const [collections, setCollections] = useState([]);
    const [nextId, setNextId] = useState(0);
    const [isEdit, setIsEdit] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [btnAddOrUpdate, setBtnAddOrUpdate] = useState("");
    const [colorSelected, setColorSelected] = useState("");
    const [nombreSelected, setNombreSelected] = useState("");
    const [ordenSelected, setOrdenSelected] = useState("");
    const [idSelected, setIdSelected] = useState("");
    const [title, setTitle] = useState("");

    function deleteCollection(id) {

        Swal.fire({
            title: '¿ Deseas eliminar esta categoría?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Borrar!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_API}delete/collection/${id}`)
                    .then(data => {
                        if (data.status === 200) {
                            Swal.fire({
                                title: 'Borrado',
                                text: 'La categoría se ha eliminado',
                                icon: 'success',
                                timer: 1500,
                                showConfirmButton: false,
                                timerProgressBar: true,
                                willClose: () => {
                                    window.location.reload();
                                }
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Hubo algun error con el servidor',
                            })
                        }
                    });
            }
        })
    }

    function changeOrder(id, downOrUp) {
        let a = collections.filter(f => f.id === id)[0];
        let orden = +a.orden;
        let b = "";
        let save = false;
        if (downOrUp === "down") {
            if (orden < collections.length) {
                save = true;
                b = collections.filter(f => f.orden === orden + 1)[0];
            } else {
                save = false;
            }

        } else {
            if (orden > 1) {
                save = true;
                b = collections.filter(f => f.orden === orden - 1)[0];
            } else {
                save = false;
            }
        }
        if (save) {
            let aux = [];
            aux.push(JSON.stringify({
                id: a.id,
                nombre: a.nombre,
                orden: b.orden,
                color: a.color
            }));
            aux.push(JSON.stringify({
                id: b.id,
                nombre: b.nombre,
                orden: a.orden,
                color: b.color
            }));

            for (let i = 0; i <= aux.length - 1; i++) {
                const collection = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: aux[i]
                }

                fetch(`${process.env.REACT_APP_API}collection/update`, collection)
                    .then(data => {
                        return data.json();
                    }).then(data => {
                        if (data.code === 201) window.location.reload();
                        if (data.code === 202) console.log(data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        }
    }

    async function getCollections() {
        return await axios.get(`${process.env.REACT_APP_API}collections`)
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function closeDialog() {
        setOpenDialog(false);
    }

    function save() {
        var objAEnviar = {};
        objAEnviar["isEdit"] = isEdit;
        objAEnviar["nombre"] = nombreSelected;
        objAEnviar["orden"] = ordenSelected;
        objAEnviar["color"] = colorSelected
        objAEnviar["id"] = idSelected;

        const requestCollection = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(objAEnviar)
        };

        fetch(`${process.env.REACT_APP_API}collection/save`, requestCollection)
            .then(data => {
                return data.json();
            }).then(data => {
                if (data.code === 201) window.location.reload();
                if (data.code === 202) console.log(data);
            })
            .catch(error => {
                console.log(error);
            })
    }

    function modal() {
        return (
            <Dialog
                maxWidth="md"
                open={openDialog}
                onClose={() => closeDialog()}
            >
                <DialogTitle id="responsive-dialog-title">
                    <Alert icon={false} severity="success">
                        <h2>{title}</h2>
                    </Alert>
                </DialogTitle>
                <DialogContent>
                    <Box style={{ color: "#4f4f4f", fontWeight: "900", background: "rgb(235,235,235)", backgroundSize: "cover", borderRadius: "10px", marginTop: "30px", marginBottom: "30px", padding: "30px" }}>
                        <div style={{ margin: "0px auto", textAlign: "center" }} className="row">
                            <div className="mt-5 col-sm-12 col-xs-12 col-md-12 row">
                                <div className="col-sm-2 col-xs-6 col-md-2">
                                    <label htmlFor="literal_es" className="form-label">ID</label>
                                    <input className="form-control" id="id" name="id"
                                        required
                                        disabled
                                        value={idSelected}
                                    />
                                </div>
                                <div className="col-sm-6 col-xs-6 col-md-6">
                                    <label htmlFor="literal_es" className="form-label">Nombre</label>
                                    <input className="form-control" id="nombreSelected" name="nombreSelected"
                                        required
                                        type="text"
                                        value={nombreSelected}
                                        onChange={(e) => { setNombreSelected(e.target.value) }}
                                    />
                                </div>
                                <div className="col-sm-2 col-xs-6 col-md-2">
                                    <label htmlFor="literal_es" className="form-label">Orden</label>
                                    <input className="form-control" id="ordenSelected" name="ordenSelected"
                                        required
                                        type="number"
                                        value={ordenSelected}
                                        onChange={(e) => { setOrdenSelected(e.target.value) }}
                                    />
                                </div>
                                <div className="col-sm-2 col-xs-6 col-md-2">
                                    <label htmlFor="literal_es" className="form-label">Color</label>
                                    <input className="form-control" id="colorSelected" name="colorSelected"
                                        required
                                        style={{ height: "54%" }}
                                        type="color"
                                        value={colorSelected}
                                        onChange={(e) => { setColorSelected(e.target.value) }}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 col-xs-12 col-md-12" style={{ textAlign: "left" }}>
                                <Button className="mt-3" type="buttom" variant="contained" onClick={() => save()}>{btnAddOrUpdate}</Button>
                            </div>
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    function newCollection() {
        setIsEdit(false);
        setOpenDialog(true);
        setBtnAddOrUpdate("Añadir");
        setTitle("Nueva colección");
        setIdSelected(nextId);
        setNombreSelected("");
        setOrdenSelected("");
        setColorSelected("");
    }

    function selectRow(id_collection) {
        var cs = collections.filter(c => c.id === id_collection)[0];
        setIsEdit(true);
        setOpenDialog(true);
        setBtnAddOrUpdate("Actualizar");
        setTitle(`Actualizando el color ${cs.id}`);
        setIdSelected(cs.id);
        setNombreSelected(cs.nombre);
        setOrdenSelected(cs.orden);
        setColorSelected(cs.color);
    }


    useEffect(() => {
        setLoading(true);
        (async () => {
            const res = await getCollections()
            setNextId(+res.length + 1);
            setCollections(res);
        })()
        setLoading(false);
    }, [])

    return (
        <>  {loading === false
            ?
            <>
                <Button className="mt-2"variant="contained" onClick={(e) => { newCollection() }}>
                    Nuevo color
                </Button>
                {modal()}
                {renderTable(collections)}
            </>
            : ""}
        </>);

    function renderTable(collections) {
        if (collections !== undefined && collections.length > 0) {
            return (<TableContainer component={Paper} style={{width: "80%",margin: "0px auto"}}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">ID</TableCell>
                            <TableCell align="left">Nombre</TableCell>
                            <TableCell align="left">Orden</TableCell>
                            <TableCell align="left">Color</TableCell>
                            <TableCell align="left"> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {collections.map((collection) => (
                            <TableRow key={collection.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left">{collection.id}</TableCell>
                                <TableCell align="left">{collection.nombre}</TableCell>
                                <TableCell align="left">{collection.orden}</TableCell>
                                <TableCell align="left"><input type="color" value={collection.color} disabled /></TableCell>
                                <TableCell>
                                    <IconButton aria-label="delete" color="error" onClick={() => { deleteCollection(collection.id) }}>
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete" color="secondary" onClick={() => { changeOrder(collection.id, "down") }}>
                                        <ArrowCircleDownIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete" color="secondary" onClick={() => { changeOrder(collection.id, "up") }}>
                                        <ArrowCircleUpIcon />
                                    </IconButton>
                                    <IconButton color="success" onClick={() => selectRow(collection.id)}>
                                        <ArrowCircleRightIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>)
        } else {
            return <h1>No hay datos</h1>
        }
    }
}



