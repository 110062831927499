import React from 'react';
import CardProduct from './cardProduct';
import Out from '../../error/out';
import Lateral from '../../comun/menu/lateral';


class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalProducts: [],
      products: [],
      error: false,
      loading: true,
      categories: [],
      id_categorySelected: "1",
      products_colors: [],
      products_tallas: [],
      tallas:[],
      colores:[]
    }
    this.findByCategory = this.findByCategory.bind(this);
    this.findByName = this.findByName.bind(this);
    this.findByRating = this.findByRating.bind(this);
  }

  
  componentDidMount() {

    fetch(`${process.env.REACT_APP_API}products`)
      .then(data => {
        return data.json();
      }).then(data => {

        this.setState({
          products: data.result,
        });

        fetch(`${process.env.REACT_APP_API}products/colors`)
          .then(data => {
            return data.json();
          }).then(data => {

            this.setState({
              products_colors: data.result,
            });

            fetch(`${process.env.REACT_APP_API}products/tallas`)
              .then(data => {
                return data.json();
              }).then(data => {

                var products = this.state.products;
                products.map(a => {
                  var colores = this.state.products_colors.filter(cp => +cp.id_product === +a.id_product);
                  var tallas = data.result.filter(t => +t.id_product === +a.id_product);
                  a["colores"] = colores;
                  return a["tallas"] = tallas;
                })
                this.setState({
                  products: products,
                  totalProducts: products,
                  loading: false,
                  products_tallas: data.result,
                });

              }).catch(err => {
                this.setState({
                  error: true
                })
              })

          }).catch(err => {
            this.setState({
              error: true
            })
          })

      }).catch(err => {
        this.setState({
          error: true
        })
      })

    fetch(`${process.env.REACT_APP_API}categories`)
      .then(data => {
        return data.json();
      }).then(data => {
        this.setState({
          categories: data.result,
        });
      }).catch(erro => {
        this.setState({
          error: true
        })
      })
      fetch(`${process.env.REACT_APP_API}tallas`)
      .then(data => {
        return data.json();
      }).then(data => {
        this.setState({
          tallas: data.result,
        });
      }).catch(erro => {
        this.setState({
          error: true
        })
      })
      fetch(`${process.env.REACT_APP_API}colores`)
      .then(data => {
        return data.json();
      }).then(data => {
        this.setState({
          colores: data.result,
        });
      }).catch(erro => {
        this.setState({
          error: true
        })
      })
  }

  findByCategory = (e) => {
    var id = +e.target.value;
    this.setState({ products: id === 1 ? this.state.totalProducts : this.state.totalProducts.filter(product => +product.id_categoria === id) })
  }

  findByCategory2 = (id) => {
    this.setState({ products: id === 1 ? this.state.totalProducts : this.state.totalProducts.filter(product => +product.id_categoria === id) })
  }

  findByName = (e) => {
    var nombre = e.target.value;
    this.setState({ products: this.state.totalProducts.filter(product => product.nombre.toLowerCase().includes(nombre.toLowerCase())) })
  }

  findByRating = (e) => {
    this.setState({ products: this.state.totalProducts.filter(product => +product.rating === +e) })
  }

  findByColor = (id) => {
    var productsSeleccionados = [];
    this.state.totalProducts.map(p => {
      if (p.colores.length > 0) {
        var hayColor = p.colores.filter(f => f.id_color === id);
        if (hayColor.length > 0) return productsSeleccionados.push(p);
      }
      return "";
    })
    this.setState({ products: productsSeleccionados })
  }

  findByTallas= (id) => {
    var productsSeleccionados = [];
    this.state.totalProducts.map(p => {
      if (p.tallas.length > 0) {
        var hayTalla = p.tallas.filter(f => f.id_talla === id);
        if (hayTalla.length > 0) return productsSeleccionados.push(p);
      }
      return "";
    })
    this.setState({ products: productsSeleccionados })
  }

  render() {
    return (
      <>
        {this.state.loading
          ? <Out loading={this.state.loading} error={this.state.error} />
          :
          <>
            <div className="col-sm-12 col-md-12 col-xs-12 col-lg-12 row">
              <div className="col-sm-6 col-md-6 col-xs-12 col-lg-4 mt-2">
                <Lateral
                  categories={this.state.categories}
                  findByCategory={this.findByCategory2}
                  findByName={this.findByName}
                  id_categorySelected={this.state.id_categorySelected}
                  colores={this.state.colores}
                  tallas={this.state.tallas}
                  findByColor={this.findByColor}
                  findByTallas={this.findByTallas} />
              </div>

              <div className='col-sm-6 col-md-6 col-xs-12 col-lg-8'>
                {/*<Buscador categories={this.state.categories} findByCategory={this.findByCategory} findByName={this.findByName} findByRating={this.findByRating} /> */}
                <div className="row" style={{ marginLeft: "10px" }}>
                  {
                    this.state.products !== 'undefined' && this.state.products.length > 0 ?
                      this.state.products.map(product => {
                        return <CardProduct key={product.id_product} product={product} />
                      }) :
                      <h3 style={{ fontFamily: "MyFont", color: "rgb(124,100,73)", fontWeight: 900, margin: "0px auto", marginTop: "20px" }}>Lo sentimos, no hay productos</h3>}
                </div>
              </div>

            </div>
          </>
        }
      </>
    )
  }
}
export default Products;