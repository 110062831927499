import React from 'react';
import Box from '@mui/material/Box';

class P3 extends React.Component {
    render() {
        var { P3TITLE, P3T1, IMAGENP3, IMAGENP3_2} = this.props;

        return (
            <Box id="sobremi" style={{ backgroundSize: "cover", marginTop: "30px", marginBottom: "30px",height: "800px" }}>
                <div className='row' style={{ margin: "0px auto" }}>
                    <div className='col-md-4 col-xs-3 col-sm-12 p3' style={{
                        backgroundImage: `url(${IMAGENP3.imagen})`, backgroundSize: "cover", height: "800px", borderTopLeftRadius: "300px",
                    }}>
                    </div>
                    <div className='col-md-4 col-sm-12 col-xs-6 p3-soyEliana center'  >
                        <h2 className='text' style={{ fontFamily: P3TITLE.fuente, color: P3TITLE.color, position: "relative", top: "20%", fontSize: P3TITLE.size, fontWeight: P3TITLE.grosor, textAlign:"center"}}>{P3TITLE.literal_es}</h2>
                        <p style={{fontFamily: P3T1.fuente, color: P3T1.color, fontSize: P3T1.size, fontWeight: P3T1.grosor,textAlign: "justify", position: "relative", top: "20%"}} className='text justify-content-center'>
                            {P3T1.literal_es}
                        </p>
                    </div>
                    <div className='col-md-4 col-xs-3 col-sm-12 p3'style={{
                        backgroundImage: `url(${IMAGENP3_2.imagen})`, backgroundSize: "cover", height: "800px", borderTopRightRadius: "300px"
                    }}></div>
                </div>
            </Box>
        );
    }
}

export default P3;