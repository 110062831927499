import React from 'react';
import Box from '@mui/material/Box';


class Contacto extends React.Component {
    render() {
        var { telefono, email, whatsapp, instagram, COLORPCONTACTO} = this.props;
        return (
            <Box id="contacto" style={{ backgroundSize: "cover", marginTop: "30px", marginBottom: "30px" }}>
                <div className='col-xs-12 col-sm-12 row'>
                    <div className='col-xs-12 col-sm-3 col-md-2 col-lg-3'></div>
                    <div className='col-xs-12 col-sm-12 col-md-8 col-lg-6' style={{ background: COLORPCONTACTO.color, height: "500px", borderTopLeftRadius: "300px", borderTopRightRadius: "300px", left: "5%" }}>
                        <div style={{ margin: "0px auto", textAlign: "center", position: "relative", top: "30%" }}>
                            <h3>Contacto</h3>
                            {telefono.enabled
                                ? <p style={{ color: telefono.color, fontFamily: telefono.fuente, fontWeight: telefono.grosor, fontSize: telefono.size }}>
                                    {telefono.literal_es}
                                </p>
                                : ""
                            }
                            {email.enabled
                                ? <p style={{ color: email.color, fontFamily: email.fuente, fontWeight: email.grosor, fontSize: email.size }}>
                                    {email.literal_es}
                                </p>
                                : ""
                            }
                            <div>
                            <div style={{ display: instagram.enabled ? "block" : "none" }}
                                dangerouslySetInnerHTML={{
                                    __html: instagram.literal_es
                                }}
                            />
                            </div>
                            <div>
                            <div style={{ display: whatsapp.enabled ? "block" : "none" }}
                                dangerouslySetInnerHTML={{
                                    __html: whatsapp.literal_es
                                }}
                            />
                            </div>
                        </div>
                    </div>
                    <div className='col-xs-12 col-sm-3 col-md-2 col-lg-3'></div>
                </div>
            </Box>
        );
    }
}

export default Contacto;