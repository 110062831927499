import React from 'react';
import P1 from '../../components/pages/p1';
import Divider from '@mui/material/Divider';
import P2 from '../../components/pages/p2';
import P3 from '../../components/pages/p3';
import P4 from '../../components/pages/p4';
import Contacto from '../../components/pages/contacto';
import Out from '../error/out';

class PaginaPrincipal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            literales: [],
            loading: true,
            error: false
        }
    }
    componentDidMount() {
        const requestLiterales = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                codigos: [
                    'title-1',
                    "title-2",
                    "telefono",
                    "email",
                    "whatsapp",
                    "instagram",
                    "P2-T1",
                    "P2-T2",
                    "P2-T3",
                    "COLOR-P2",
                    "P2-TITLE",
                    "P3-TITLE",
                    "P3-T1",
                    "ENABLED_P1",
                    "ENABLED_P2",
                    "ENABLED_P3",
                    "ENABLED_P4",
                    "IMAGEN_P1",
                    "IMAGEN_P2",
                    "IMAGEN_P3",
                    "IMAGEN_P3-2",
                    "COLOR_PCONTACTO"
                ]
            })
        };

        fetch(`${process.env.REACT_APP_API}literales/by/codigos`, requestLiterales)
            .then(data => {
                return data.json();
            }).then(data => {
                this.setState({
                    literales: data.result,
                    loading: false,
                })
            }).catch(erro => {
                this.setState({
                    error: true
                })
            })
    }

    render() {
        var telefono = this.state.literales.filter(f => f.codigo === "telefono")[0];
        var email = this.state.literales.filter(f => f.codigo === "email")[0];
        var instagram = this.state.literales.filter(f => f.codigo === "instagram")[0];
        var whatsapp = this.state.literales.filter(f => f.codigo === "whatsapp")[0];
        var title1 = this.state.literales.filter(f => f.codigo === "title-1")[0];
        var title2 = this.state.literales.filter(f => f.codigo === "title-2")[0];

        var P2T1 = this.state.literales.filter(f => f.codigo === "P2-T1")[0];
        var P2T2 = this.state.literales.filter(f => f.codigo === "P2-T2")[0];
        var P2T3 = this.state.literales.filter(f => f.codigo === "P2-T3")[0];
        var COLORP2 = this.state.literales.filter(f => f.codigo === "COLOR-P2")[0];
        var P2TITLE = this.state.literales.filter(f => f.codigo === "P2-TITLE")[0];

        var P3TITLE = this.state.literales.filter(f => f.codigo === "P3-TITLE")[0];
        var P3T1 = this.state.literales.filter(f => f.codigo === "P3-T1")[0];

        var ENABLED_P1 = this.state.literales.filter(f => f.codigo === "ENABLED_P1")[0];
        var ENABLED_P2 = this.state.literales.filter(f => f.codigo === "ENABLED_P2")[0];
        var ENABLED_P3 = this.state.literales.filter(f => f.codigo === "ENABLED_P3")[0];
        var ENABLED_P4 = this.state.literales.filter(f => f.codigo === "ENABLED_P4")[0];

        var IMAGENP1 = this.state.literales.filter(f => f.codigo === "IMAGEN_P1")[0];
        var IMAGENP2 = this.state.literales.filter(f => f.codigo === "IMAGEN_P2")[0];
        var IMAGENP3 = this.state.literales.filter(f => f.codigo === "IMAGEN_P3")[0];
        var IMAGENP3_2 = this.state.literales.filter(f => f.codigo === "IMAGEN_P3-2")[0];

        var COLORPCONTACTO = this.state.literales.filter(f => f.codigo === "COLOR_PCONTACTO")[0];

        return (
            <>
                {this.state.loading
                    ? <Out loading={this.state.loading} error={this.state.error} />
                    :
                    <>
                        {ENABLED_P1.enabled ?
                            <>
                                <P1 t1={title1} t2={title2} IMAGENP1={IMAGENP1} />
                                <Divider />
                            </> : ""}

                        {ENABLED_P2.enabled ?
                            <>
                                <P2 P2T1={P2T1} P2T2={P2T2} P2T3={P2T3} COLORP2={COLORP2} P2TITLE={P2TITLE} IMAGENP2={IMAGENP2} />
                                <Divider />
                            </> : ""}

                        {ENABLED_P3.enabled ?
                            <>
                                <P3 P3TITLE={P3TITLE} P3T1={P3T1} IMAGENP3={IMAGENP3} IMAGENP3_2={IMAGENP3_2} />
                                <Divider />
                            </> : ""}

                        {ENABLED_P4.enabled ?
                            <>
                                <P4 />
                                <Divider />
                            </> : ""}

                        <Contacto
                            COLORPCONTACTO={COLORPCONTACTO}
                            telefono={telefono}
                            email={email}
                            whatsapp={whatsapp}
                            instagram={instagram}
                        />
                    </>
                }
            </>
        );
    }
}
export default PaginaPrincipal;