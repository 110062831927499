import React from 'react';
import Box from '@mui/material/Box';
import P10 from '../../_imagenes/p10.svg';
import P11 from '../../_imagenes/p11.svg';
import P12 from '../../_imagenes/p12.svg';

class P2 extends React.Component {

    render() {
        var { P2T1, P2T2, P2T3, COLORP2, IMAGENP2} = this.props;

        return (
            <Box style={{ background: COLORP2.color, backgroundSize: "cover", borderRadius: "10px", marginTop: "30px", marginBottom: "30px", padding: "30px" }}>
                <div className='row col-md-12'>
                    <div className="col-md-8 col-xs-12 center" style={{ padding: "5%" }}>
                        <div >
                            <h1 style={{ fontFamily: "MyFont", color: "rgb(207,161,84)", fontWeight: 600, textAlign: "center" }}>Nuestros productos</h1>
                            <div>
                                <div className='row mt-5'>
                                    <div className="col-xs-6 col-md-6 div-figuras">
                                        <img src={P10} style={{ float: "right" }} alt="img-10" />
                                    </div>
                                    <div className="col-xs-6 col-md-6">
                                        <div style={{ display: P2T1.enabled ? "block" : "none" }}
                                            dangerouslySetInnerHTML={{
                                                __html: P2T1.literal_es
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='row  mt-5'>
                                    <div className="col-xs-6 col-md-6 div-figuras">
                                        <img src={P11} style={{ float: "right" }} alt="img-11" />
                                    </div>
                                    <div className="col-xs-6 col-md-6">
                                    <div style={{ display: P2T2.enabled ? "block" : "none" }}
                                            dangerouslySetInnerHTML={{
                                                __html: P2T2.literal_es
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='row  mt-5'>
                                    <div className="col-xs-6 col-md-6 div-figuras">
                                        <img src={P12} style={{ float: "right" }} alt="img-12" />
                                    </div>
                                    <div className="col-xs-6 col-md-6" >
                                    <div style={{ display: P2T3.enabled ? "block" : "none" }}
                                            dangerouslySetInnerHTML={{
                                                __html: P2T3.literal_es
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="col-md-3 col-xs-12 p3" style={{ backgroundImage: `url(${IMAGENP2.imagen})`, backgroundSize: "cover", height: "800px", borderTopRightRadius: "300px", margin: "10px" }}>
                    </div>
                </div>
            </Box>
        );
    }
}

export default P2;