import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import Login from '../login/login';

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    const logout = () => {
        localStorage.setItem('login', false);
        localStorage.setItem('user', "");
        window.location.reload();
    }

    return (
        <AppBar position="fixed" style={{ background: "rgb(245,244,239)" }}>
            <Container maxWidth="xl" style={{ height: "100px" }}>
                <Toolbar disableGutters>
                    <Typography
                        className='title-menu'
                        style={{ textDecoration: "none" }}
                        variant="h4"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            mt: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: "MyFont",
                            fontWeight: 900,
                            letterSpacing: '.3rem',
                            color: 'rgb(207,161,84)',
                            textDecoration: 'none',
                        }}
                    >
                        EP | BOUTIQUE
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon style={{ color: "rgb(124,100,73)" }} />
                        </IconButton>
                        <Menu id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                            keepMounted
                            transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{ display: { xs: 'block', md: 'none' }, }}
                        >
                            <MenuItem >
                                <Button sx={{ color: 'white', display: 'block' }} style={{ color: "rgb(124,100,73)" }} href="/products">
                                    Productos
                                </Button>
                            </MenuItem>
                            <MenuItem >
                                <Button sx={{ my: 2, color: 'white', display: 'block' }} style={{ color: "rgb(124,100,73)" }} href="#sobremi">
                                    Sobre mi
                                </Button>
                            </MenuItem>

                            <MenuItem >
                                <Button sx={{ my: 2, color: 'white', display: 'block' }} style={{ color: "rgb(124,100,73)" }} href="/colecciones">
                                    Colecciones
                                </Button>
                            </MenuItem>

                            <MenuItem >
                                <Button sx={{ my: 2, color: 'white', display: 'block' }} style={{ color: "rgb(124,100,73)" }} href="#contacto">
                                    Contacto
                                </Button>
                            </MenuItem>

                        </Menu>
                    </Box>
                    <Typography variant="h4" noWrap component="a" href="/"
                        style={{ textDecoration: "none" }}
                        className='title-principal'
                        sx={{
                            mr: 15,
                            mt: 2,
                            display: { xs: 'flex', md: 'none' },
                            fontFamily: "MyFont",
                            fontWeight: 900,
                            letterSpacing: '.3rem',
                            color: 'rgb(207,161,84)',
                            textDecoration: 'none',
                        }}
                    >
                        EP | BOUTIQUE
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        <Button sx={{ my: 2, color: 'white', display: 'block' }} style={{ color: "rgb(124,100,73)" }} href="/products">
                            Productos
                        </Button>
                        <Button sx={{ my: 2, color: 'white', display: 'block' }} style={{ color: "rgb(124,100,73)" }} href="#sobremi">
                            Sobre mi
                        </Button>
                        <Button sx={{ my: 2, color: 'white', display: 'block' }} style={{ color: "rgb(124,100,73)" }} href="/colecciones">
                            Colecciones
                        </Button>
                        <Button sx={{ my: 2, color: 'white', display: 'block' }} style={{ color: "rgb(124,100,73)" }} href="#contacto">
                            Contacto
                        </Button>
                    </Box>
                    <Login />
                    <Box sx={{ flexGrow: 0 }} style={{ display: localStorage.getItem('login') === "true" ? "block" : "none" }}>
                        <Tooltip title="opciones">
                            <LogoutIcon onClick={handleOpenUserMenu} sx={{ p: 0 }} style={{ cursor: "pointer", color: "rgb(124,100,73)" }} />
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                            keepMounted
                            transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem>
                                <Button sx={{ color: 'white', display: 'block' }} style={{ color: "rgb(124,100,73)" }} href="/adm/products">
                                    Productos
                                </Button>
                            </MenuItem>
                            <MenuItem>
                                <Button sx={{ color: 'white', display: 'block' }} style={{ color: "rgb(124,100,73)" }} href="/adm/literales">
                                    Literales
                                </Button>
                            </MenuItem>
                            <MenuItem>
                                <Button sx={{ color: 'white', display: 'block' }} style={{ color: "rgb(124,100,73)" }} href="/adm/colores">
                                    Colores
                                </Button>
                            </MenuItem>
                            <MenuItem>
                                <Button sx={{ color: 'white', display: 'block' }} style={{ color: "rgb(124,100,73)" }} href="/adm/tallas">
                                    Tallas
                                </Button>
                            </MenuItem>
                            <MenuItem>
                                <Button sx={{ color: 'white', display: 'block' }} style={{ color: "rgb(124,100,73)" }} href="/adm/colecciones">
                                    Colecciones
                                </Button>
                            </MenuItem>
                            <MenuItem>
                                <Button sx={{ color: 'white', display: 'block' }} style={{ color: "rgb(124,100,73)" }} onClick={logout}>
                                    Salir
                                </Button>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;