import React from "react";

import { Box, Link, Checkbox, Button, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, IconButton, MenuItem, OutlinedInput, FormControl, InputLabel, Select } from '@mui/material';
import Out from '../../error/out';
import Swal from 'sweetalert2'
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
class Literales extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            button: "Nuevo Literal",
            literales: [],
            nextId: 0,
            loading: true,
            error: false,
            idSelected: "",
            codigoSelected: "",
            literal_esSelected: "",
            literal_enSelected: "",
            colorSelected: "",
            fuenteSelected: "",
            grosorSelected: "",
            enabledSelected: "1",
            sizeSelected: "",
            imagenSelected: "",
            apartadoSelected: "",
            openDialog: false,
            isEdit: false,
            tab: 0,
            gurpos: []
        }
        this.delete = this.delete.bind(this);
        this.changeVisible = this.changeVisible.bind(this);
        this.save = this.save.bind(this);
        this.changeTab = this.changeTab.bind(this);
    }
    changeTab = (e, newValue) => {
        this.setState({
            tab: newValue
        })

    };

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API}literales`)
            .then(data => {
                return data.json();
            }).then(data => {

                var apartados = [];
                var aux = [];
                data.result.map(l => {
                    if (apartados.includes(l.apartado)) {
                        aux[l.apartado].push(l);
                    } else {
                        aux[l.apartado] = [];
                        aux[l.apartado].push(l);
                        apartados.push(l.apartado);
                    }
                    return "";
                })
                const sorted = Object.fromEntries(Object.entries(aux).sort((a, b) => a[0].key - b[0].key));
                console.log(sorted)
                this.setState({
                    grupos: aux,
                    literales: data.result,
                    nextId: data.result.length > 0 ? Math.max(...data.result.map(p => p.id)) + 1 : 0,
                    loading: false
                });
            }).catch(erro => {
                this.setState({
                    error: true
                })
            })
    }


    delete(e, id) {

        Swal.fire({
            title: '¿ Deseas eliminar este literal?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Borrar!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_API}delete/literal/${id}`)
                    .then(data => {
                        if (data.status === 200) {
                            Swal.fire({
                                title: 'Borrado',
                                text: 'El literal se ha eliminado',
                                icon: 'success',
                                timer: 1500,
                                showConfirmButton: false,
                                timerProgressBar: true,
                                willClose: () => {
                                    window.location.reload();
                                }
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Hubo algun error con el servidor',
                            })
                        }
                    });
            }
        })
    }

    changeVisible = (id, value) => {
        var visible = value === false ? 0 : 1;
        fetch(`${process.env.REACT_APP_API}literal/visible/${visible}/${id}`)
            .then(data => {
                return data.json();
            }).then(data => {
                window.location.reload();
            }).catch(erro => {
                this.setState({
                    error: true
                })
            })
    }


    selectRow = (e) => {
        var l = this.state.literales.filter(p => p.id === e)[0];
        this.setState({
            openDialog: true,
            title: `Actualizando el literal ${l.codigo}`,
            button: "Actualizar",
            isEdit: true
        })
        Object.keys(l).map(k => {
            var keyReal = `${k}Selected`
            return this.setState({ [keyReal]: l[k] })
        });
    }

    openDialog = () => {
        Object.keys(this.state).map(k => {
            if (k.includes("Selected")) {
                return this.setState({ [k]: "" })
            }
            return "";
        });
        this.setState({
            isEdit: false,
            idSelected: this.state.nextId,
            title: "Nuevo literal",
            button: "Add",
            openDialog: true
        });
    };

    closeDialog = () => {
        this.setState({
            openDialog: false
        })
    };


    save = async () => {
        var objAEnviar = {};
        Object.keys(this.state).map(k => {
            if (k.includes("Selected")) {
                var aux = k.substring(0, k.indexOf("S"));
                objAEnviar[aux] = this.state[k]
            }
            return "";
        })
        objAEnviar["isEdit"] = this.state.isEdit;
        const requestLiteral = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(objAEnviar)
        };

        fetch(`${process.env.REACT_APP_API}literal/save`, requestLiteral)
            .then(data => {
                return data.json();
            }).then(data => {
                if (data.code === 201) window.location.reload();
                if (data.code === 202) console.log(data);
            })
            .catch(erro => {
                this.setState({
                    error: true
                })
            })
    }

    setCamposLiteral = (campo, valor) => {
        this.setState({ [campo]: valor });
    }

    render() {

        return (

            <>
                {this.state.loading
                    ? <Out loading={this.state.loading} error={this.state.error} />
                    :
                    <>

                        <Dialog
                            fullWidth
                            maxWidth="md"
                            open={this.state.openDialog}
                            onClose={this.closeDialog}
                        >
                            <DialogTitle id="responsive-dialog-title">
                                <Alert icon={false} severity="success">
                                    <h2>{this.state.title}</h2>
                                </Alert>
                            </DialogTitle>

                            <DialogContent>
                                <Box style={{ color: "#4f4f4f", fontWeight: "900", background: "rgb(235,235,235)", backgroundSize: "cover", borderRadius: "10px", marginBottom: "30px", padding: "30px" }}>
                                    <div style={{ margin: "0px auto", textAlign: "center" }} className="row">
                                        <div className="col-sm-12 col-xs-12 col-md-12 row">

                                            <div className="col-xs-12 col-md-6">
                                                <label htmlFor="id" className="form-label">ID</label>
                                                <input type="text" className="form-control"
                                                    disabled
                                                    value={this.state.idSelected}
                                                    id="id" name="id" />
                                            </div>
                                            <div className="col-xs-12 col-md-6">
                                                <label htmlFor="codigo" className="form-label">Código</label>
                                                <input type="text" className="form-control"
                                                    required
                                                    onChange={(e) => { this.setCamposLiteral("codigoSelected", e.target.value) }}
                                                    value={this.state.codigoSelected}
                                                    id="codigo" name="codigo" />
                                            </div>
                                        </div>

                                        <div className="mt-5 col-sm-12 col-xs-12 col-md-12 row">
                                            <div className="col-sm-12 col-xs-12 col-md-12">
                                                <label htmlFor="literal_en" className="form-label">Literal en ingles</label>
                                                <textarea className="form-control" id="literal_en" name="literal_en" rows="3"
                                                    value={this.state.literal_enSelected}
                                                    onChange={(e) => { this.setCamposLiteral("literal_enSelected", e.target.value) }}
                                                    required />
                                            </div>
                                        </div>

                                        <div className="mt-5 col-sm-12 col-xs-12 col-md-12 row">
                                            <div className="col-sm-12 col-xs-12 col-md-12">
                                                <label htmlFor="literal_es" className="form-label">Literal en Español</label>
                                                <textarea className="form-control" id="literal_es" name="literal_es" rows="3"
                                                    required
                                                    value={this.state.literal_esSelected}
                                                    onChange={(e) => { this.setCamposLiteral("literal_esSelected", e.target.value) }}
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-5 col-sm-12 col-xs-12 col-md-12 row">


                                            <div className="col-xs-12 col-md-2">
                                                <label htmlFor="Color" className="form-label">Color</label>
                                                <input type="color" className="form-control"
                                                    required
                                                    style={{ height: "54%" }}
                                                    onChange={(e) => { this.setCamposLiteral("colorSelected", e.target.value) }}
                                                    value={this.state.colorSelected}
                                                    id="color" name="color" />
                                            </div>

                                            <div className="col-xs-12 col-md-5">
                                                <label htmlFor="fuente" className="form-label">Fuente</label>
                                                <input type="text" className="form-control"
                                                    required
                                                    onChange={(e) => { this.setCamposLiteral("fuenteSelected", e.target.value) }}
                                                    value={this.state.fuenteSelected}
                                                    id="fuente" name="fuente" />
                                            </div>


                                            <div className="col-xs-12 col-md-2">
                                                <label htmlFor="grosor" className="form-label">Grosor</label>
                                                <input type="text" className="form-control"
                                                    required
                                                    onChange={(e) => { this.setCamposLiteral("grosorSelected", e.target.value) }}
                                                    value={this.state.grosorSelected}
                                                    id="grosor" name="grosor" />
                                            </div>
                                            <div className="col-xs-12 col-md-2">
                                                <label htmlFor="size" className="form-label">Tamaño</label>
                                                <input type="text" className="form-control"
                                                    required
                                                    onChange={(e) => { this.setCamposLiteral("sizeSelected", e.target.value) }}
                                                    value={this.state.sizeSelected}
                                                    id="size" name="size" />
                                            </div>
                                        </div>

                                        <div className="mt-5 col-sm-12 col-xs-12 col-md-12 row">
                                            <div className="col-sm-12 col-xs-12 col-md-12">
                                                <label htmlFor="url_imagen" className="form-label">URL Imagen</label>
                                                <textarea className="form-control" id="imagen" name="imagen" rows="3"
                                                    required
                                                    value={this.state.imagenSelected}
                                                    onChange={(e) => { this.setCamposLiteral("imagenSelected", e.target.value) }}
                                                />
                                            </div>

                                            <div className="col-md-6 col-xs-6 mt-2">

                                                <FormControl fullWidth style={{ background: "white" }}>
                                                    <InputLabel id="apartado">Pertenece al apartado </InputLabel>
                                                    <Select
                                                        labelId="apartado"
                                                        value={this.state.apartadoSelected}
                                                        onChange={(e) => { this.setCamposLiteral("apartadoSelected", e.target.value) }}
                                                        name="apartado"
                                                        id="apartado"
                                                        input={<OutlinedInput label="Apartado" />}
                                                    >
                                                        <MenuItem key={"General"} value={"General"}>General</MenuItem>
                                                        <MenuItem key={"P1"} value={"P1"}>Página 1</MenuItem>
                                                        <MenuItem key={"P2"} value={"P2"}>Página 2</MenuItem>
                                                        <MenuItem key={"P3"} value={"P3"}>Página 3</MenuItem>
                                                        <MenuItem key={"P4"} value={"P4"}>Página 4</MenuItem>
                                                        <MenuItem key={"PContacto"} value={"PContacto"}>Página contacto</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-xs-12 col-md-12" style={{ textAlign: "left" }}>
                                            <Button className="mt-3" type="buttom" variant="contained" onClick={this.save}>{this.state.button}</Button>
                                        </div>
                                    </div>
                                </Box>
                            </DialogContent>
                        </Dialog>

                        <Tabs
                            id="controlled-tab-example"
                            defaultActiveKey={0}
                            className="mb-3"
                        >
                            {Object.entries(this.state.grupos).map((obj, i) => {
                                const key = obj[0];
                                const value = obj[1];
                                return (
                                    <Tab eventKey={i} title={key} >
                                        <div style={{ width: "99%", margin: "0px auto", textAlign: "center" }} className="mt-5 mb-5 row">
                                            
                                            <div className='col-sm-12 col-md-12 col-xs-12 mb-2' >
                                                <Button variant="contained" className='title-aux' onClick={(e) => { this.openDialog(false) }}>
                                                    Nuevo Literal
                                                </Button>
                                            </div>

                                            <Link to={`/adm/products`} style={{ color: "green" }} component={RouterLink}>
                                                <ArrowBackIcon />
                                            </Link>
                                            <TableContainer component={Paper}>
                                                <Table size="small" aria-label="a dense table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left">ID</TableCell>
                                                            <TableCell align="left">Codigo</TableCell>
                                                            <TableCell align="left">Literal en Español</TableCell>
                                                            <TableCell align="left">Literal en Ingles</TableCell>
                                                            <TableCell align="left">Fuente</TableCell>
                                                            <TableCell align="left">Color</TableCell>
                                                            <TableCell align="left">Grosor</TableCell>
                                                            <TableCell align="left">Tamaño</TableCell>
                                                            <TableCell align="left">¿Es visible?</TableCell>
                                                            <TableCell align="left" colSpan={2}> </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {value.map((literal) => (
                                                            <TableRow key={literal.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                <TableCell align="left">{literal.id}</TableCell>
                                                                <TableCell align="left">{literal.codigo}</TableCell>
                                                                <TableCell align="left">{literal.literal_es}</TableCell>
                                                                <TableCell align="left">{literal.literal_en}</TableCell>
                                                                <TableCell align="left">{literal.fuente}</TableCell>
                                                                <TableCell align="left"><input type="color" value={literal.color} disabled /></TableCell>
                                                                <TableCell align="left">{literal.grosor}</TableCell>
                                                                <TableCell align="left">{literal.size}</TableCell>
                                                                <TableCell align="left">
                                                                    <Checkbox checked={literal.enabled === 1 ? true : false} onChange={(e, v) => { this.changeVisible(literal.id, v) }} />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <IconButton aria-label="delete" color="error" onClick={(e) => { this.delete(e, literal.id) }}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Tooltip title="Ver detalle del producto">
                                                                        <ArrowCircleRightIcon color="success" onClick={() => this.selectRow(literal.id)} style={{ cursor: "pointer" }} />
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </Tab>

                                )
                            })}
                        </Tabs>
                    </>
                }
            </>
        )
    }
}

export default Literales;