import React from "react";
import { Box, Link, Button, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import Out from '../../error/out';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Swal from 'sweetalert2'
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';


class Tallas extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tallas: [],
            nextId: 0,
            loading: true,
            openDialog: false,
            idSelected: 0,
            tallaSelected: "",
        }
        this.modal = this.modal.bind(this);
        this.save = this.save.bind(this);
        this.renderTabla = this.renderTabla.bind(this);
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API}tallas`)
            .then(data => {
                return data.json();
            }).then(data => {
                this.setState({
                    tallas: data.result,
                    nextId: data.result.length > 0 ? Math.max(...data.result.map(p => p.id)) + 1 : 0,
                    loading: false
                });
            }).catch(erro => {
                this.setState({
                    error: true
                })
            })
    }

    newTalla = () => {
        this.setState({
            openDialog: true,
            idSelected: this.state.nextId
        })
    }

    closeDialog = () => {
        this.setState({
            openDialog: false
        })
    }

    setCampos = (campo, valor) => {
        this.setState({ [campo]: valor });
    }

    modal = () => {
        return (
            <Dialog
                maxWidth="md"
                open={this.state.openDialog}
                onClose={this.closeDialog}
            >
                <DialogTitle id="responsive-dialog-title">
                    <Alert icon={false} severity="success">
                        <h2>Agregar talla</h2>
                    </Alert>
                </DialogTitle>
                <DialogContent>
                    <Box style={{ color: "#4f4f4f", fontWeight: "900", background: "rgb(235,235,235)", backgroundSize: "cover", borderRadius: "10px", marginTop: "30px", marginBottom: "30px", padding: "30px" }}>
                        <div style={{ margin: "0px auto", textAlign: "center" }} className="row">
                            <div className="mt-5 col-sm-12 col-xs-12 col-md-12 row">
                                <div className="col-sm-4 col-xs-6 col-md-4">
                                    <label htmlFor="literal_es" className="form-label">ID</label>
                                    <input className="form-control" id="id" name="id"
                                        required
                                        disabled
                                        value={this.state.idSelected}
                                    />
                                </div>
                                <div className="col-sm-6 col-xs-6 col-md-6">
                                    <label htmlFor="literal_es" className="form-label">Talla</label>
                                    <input className="form-control" id="tallaSelected" name="tallaSelected"
                                        required
                                        style={{ height: "54%" }}
                                        type="text"
                                        value={this.state.tallaSelected}
                                        onChange={(e) => { this.setCampos("tallaSelected", e.target.value) }}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 col-xs-12 col-md-12" style={{ textAlign: "left" }}>
                                <Button className="mt-3" type="buttom" variant="contained" onClick={this.save}>Agregar talla</Button>
                            </div>
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    save = async () => {
        var objAEnviar = {};
        Object.keys(this.state).map(k => {
            if (k.includes("Selected")) {
                var aux = k.substring(0, k.indexOf("S"));
                objAEnviar[aux] = this.state[k]
            }
            return "";
        })
        const requestColor = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(objAEnviar)
        };

        fetch(`${process.env.REACT_APP_API}talla/save`, requestColor)
            .then(data => {
                return data.json();
            }).then(data => {
                if (data.code === 201) window.location.reload();
                if (data.code === 202) console.log(data);
            })
            .catch(erro => {
                this.setState({
                    error: true
                })
            })
    }

    delete(id) {
        Swal.fire({
            title: '¿ Deseas eliminar esta talla?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Borrar!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_API}delete/talla/${id}`)
                    .then(data => {
                        if (data.status === 200) {
                            Swal.fire({
                                title: 'Borrado',
                                text: 'La talla se ha eliminado',
                                icon: 'success',
                                timer: 1500,
                                showConfirmButton: false,
                                timerProgressBar: true,
                                willClose: () => {
                                    window.location.reload();
                                }
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Hubo algun error con el servidor',
                            })
                        }
                    });
            }
        })
    }

    renderTabla = () => {
        return (
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">ID</TableCell>
                            <TableCell align="left">Talla</TableCell>
                            <TableCell align="left" colSpan={2}> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.tallas.map((talla) => (
                            <TableRow key={talla.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left">{talla.id}</TableCell>
                                <TableCell align="left">{talla.talla}</TableCell>
                                <TableCell>
                                    <IconButton aria-label="delete" color="error" onClick={(e) => { this.delete(talla.id) }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    render() {
        return (
            <>
                {this.state.loading
                    ? <Out loading={this.state.loading} error={this.state.error} />
                    : <>
                        {this.modal()}
                        <div style={{ width: "50%", margin: "0px auto" }} className="mt-5 mb-5 row">
                            <div className='col-sm-12 col-md-12 col-xs-12 mb-5 row'>
                                <Button variant="contained" onClick={(e) => { this.newTalla() }}>
                                    Nueva talla
                                </Button>
                            </div>
                            <div className="col-sm-12 col-md-12 col-xs-12 mb-5 row">
                                <Link to={`/adm/products`} style={{ color: "green" }} component={RouterLink}>
                                    <ArrowBackIcon />
                                </Link>
                            </div>
                            {this.renderTabla()}
                        </div>
                    </>}
            </>
        );
    }
}

export default Tallas;