import React from 'react';
import Box from '@mui/material/Box';
import imagen5 from '../../_imagenes/p5.jpeg';
import imagen6 from '../../_imagenes/p6.jpeg';
import imagen7 from '../../_imagenes/p7.jpeg';
import imagen8 from '../../_imagenes/p8.jpeg';
import imagen9 from '../../_imagenes/p9.jpeg';


class P4 extends React.Component {
    render() {
        return (
            <Box id="colecciones" style={{ backgroundSize: "cover", marginTop: "30px", marginBottom: "30px" }}>
                <div className='col-xs-12 row'>
                    
                    <div className='col-xs-12 col-md-3' style={{margin: "0px auto", textAlign:"center"}}>
                        <img src={`${imagen5}`} height={300} width={250} style={{ objectFit: "cover" }} alt={"p1"} className="f"></img>
                        <div style={{ padding: "10px" }}>
                            <h3 style={{ fontFamily: "MyFont", color: "rgb(207,161,84)",fontWeight: 900 }}>COLLARES</h3>
                            <p style={{ fontFamily: "MyFont", fontWeight: 900 }}>
                                Un collar siempre será tu mejor
                                opción, ya sean delgados ajustados al
                                cuello, cuadrados, rectangulares, con
                                dijes, recuerda elegir el que más se
                                ajuste a tu estilo.
                                En mis historias destacadas, te dejaré
                                algunos tips para que sepas
                                seleccionarlos. </p>
                        </div>
                    </div>

                    <div className='col-xs-12 col-md-3' style={{margin: "0px auto", textAlign:"center"}}>
                        <img src={`${imagen6}`} height={300} width={250} style={{ objectFit: "cover" }} alt={"p2"} className="f"></img>
                        <div style={{ padding: "10px" }}>
                            <h3 style={{ fontFamily: "MyFont", color: "rgb(207,161,84)",fontWeight: 900 }}>ARETES GEOMETRIC</h3>
                            <p style={{ fontFamily: "MyFont", fontWeight: 900 }}>
                                El complemento perfecto, ayudan a
                                resaltar tu belleza, marcan la
                                diferencia en tus outfits.
                            </p>
                        </div>
                    </div>

                    <div className='col-xs-12 col-md-3' style={{margin: "0px auto", textAlign:"center"}}>
                        <img src={`${imagen7}`} height={300} width={250} style={{ objectFit: "cover" }} alt={"p3"}  className="f"></img>
                        <div style={{ padding: "10px" }}>
                            <h3 style={{ fontFamily: "MyFont", color: "rgb(207,161,84)",fontWeight: 900 }}> PULSERAS AESTHETIC</h3>
                            <p style={{ fontFamily: "MyFont", fontWeight: 900 }}>
                                Ayudaran siempre a resaltar tu
                                belleza, marcan la diferencia en tus
                                outfits y hay tenemos para ti,
                                muchas variedades y colores.
                            </p>
                        </div>
                    </div>

                    <div className='col-xs-12 col-md-3' style={{margin: "0px auto", textAlign:"center"}}>
                        <img src={`${imagen8}`} height={300} width={250} style={{ objectFit: "cover" }} alt={"p4"}  className="f"></img>
                        <div style={{ padding: "10px" }}>
                            <h3 style={{ fontFamily: "MyFont", color: "rgb(207,161,84)",fontWeight: 900 }}> PAÑUELOS</h3>
                            <p style={{ fontFamily: "MyFont", fontWeight: 900}}>
                                Son la tendencia, vienen geniales para esta
                                temporada primavera - verano , Te hacen
                                ver elegante, y son prácticos de usar
                                En mis historias destacadas, te dejaré
                                algunos tips para que sepas seleccionarlos.
                            </p>
                        </div>
                    </div>

                    <div className='col-xs-12 col-md-3' style={{margin: "0px auto", textAlign:"center"}}>
                        <img src={`${imagen9}`} height={300} width={250} style={{ objectFit: "cover" }} alt={"p5"}  className="f"></img>
                        <div style={{ padding: "10px" }}>
                            <h3 style={{ fontFamily: "MyFont", color: "rgb(207,161,84)",fontWeight: 900 }}> PIJAMAS</h3>
                            <p style={{ fontFamily: "MyFont", fontWeight: 900 }}>
                                “ No hay cuerpo más bello, que aquel
                                que se acepta “
                                Crea tu outfit de dormir perfecto con
                                nuestros pijamas y accesorios a juego.
                            </p>
                        </div>
                    </div>
                </div>
            </Box>
        );
    }
}

export default P4;