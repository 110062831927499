import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';

class Footer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            anchorElNav: null,
            anchorElUser: null
        }
    }
    handleOpenNavMenu = (event) => {
        this.setState({
            anchorElNav: event.currentTarget
        })
    };
    handleOpenUserMenu = (event) => {
        this.setState({
            anchorElUser: event.currentTarget
        })
    };

    handleCloseNavMenu = () => {
        this.setState({
            anchorElNav: null
        })
    };

    handleCloseUserMenu = () => {
        this.setState({
            anchorElUser: null
        })
    };

    render() {
        return (
            <AppBar position="static" style={{ background: "rgb(245,244,239)", bottom: 0, position: "fixed" }}  >
                <Container maxWidth="xl">
                    <Toolbar>
                        <Box sx={{ flexGrow: 1, display: { md: 'flex' } }} style={{ fontWeight: '800', color: '#c6c6c6', textAlign: 'center', opacity: '0.6' }}>
                            <p style={{ margin: "0px auto" }}> {'Copyright ©  EP '}
                                {new Date().getFullYear()}
                                {'.'}</p>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        );
    }
}
export default Footer