import React from "react";
import LoginIcon from '@mui/icons-material/Login';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';


class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            user: "",
            password: ""
        }

    }

    handleClickOpen = () => {
        this.setState({
            open: true
        })
    };

    handleClose = () => {
        this.setState({
            open: false
        })
    };

    login = () => {
        const requestLogin = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                user: this.state.user,
                password: this.state.password
            })
        };
        fetch(`${process.env.REACT_APP_API}login`, requestLogin)
            .then(data => { return data.json() })
            .then(data => {
                localStorage.setItem('login', true);
                localStorage.setItem('user', data.result[0].nombre);
                window.location.reload();
            })
    }

    render() {
        return (
            <>
                <Box sx={{ flexGrow: 0 }} style={{display: localStorage.getItem('login') ===null || localStorage.getItem('login') === "false"? "block": "none"}}>
                    <Tooltip title="Login">
                        <LoginIcon onClick={this.handleClickOpen} sx={{ p: 0 }} style={{ cursor: "pointer", color: "rgb(124,100,73)" }} />
                    </Tooltip>
                </Box>
                <Dialog
                    fullWidth
                    maxWidth="xs"
                    open={this.state.open}
                    onClose={this.handleClose}
                >

                    <DialogTitle>Login</DialogTitle>
                    <DialogContent>

                        <div className="col-sm-12 col-xs-12 col-sm-12" style={{ textAlign: "center" }}>
                            <div className="col-sm-12 col-xs-12 col-sm-12 mt-2">
                                <TextField
                                    style={{ borderColor: "white !important", color: "white !important" }}
                                    id="outlined-password-input"
                                    label="User"
                                    type="text"
                                    autoComplete="current-password"
                                    value={this.state.user}
                                    onChange={(e) => { this.setState({ user: e.target.value }) }}
                                />
                            </div>
                            <div className="col-sm-12 col-xs-12 col-sm-12 mt-2">
                                <TextField
                                    id="outlined-password-input"
                                    label="Password"
                                    type="password"
                                    autoComplete="current-password"
                                    value={this.state.password}
                                    onChange={(e) => { this.setState({ password: e.target.value }) }}
                                />
                            </div>
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={this.login}>
                            Login
                        </Button>
                        <Button variant="contained" color="secondary" onClick={this.handleClose}>
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog >
            </>
        )
    }
}

export default Login;