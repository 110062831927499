import React from 'react';
import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


class BtnWhatsApp extends React.Component {
    render() {
        return (
            <a href={`https://wa.me/${process.env.REACT_APP_NUMERO_WHATSAPP}?text=Hola!!`}><Fab aria-label="add" style={{ position: "fixed", bottom: "80px", right: "10px", color: "#25D366" }}>
                <WhatsAppIcon sx={{ fontSize: 40 }} />
            </Fab></a>
        )
    }
}

export default BtnWhatsApp;