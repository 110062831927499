import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, TextField } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Rating from '@mui/material/Rating';

class Buscador extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rating: 0,
            id_categorySelected: ""
        }
    }

    render() {
        var { categories } = this.props;
        return (
            <div className='col-xs-12, col-sm-12 col-md-12 col-lg-12'>

                <Accordion style={{color: "rgb(124,100,73)", fontFamily:"MyFont", fontWeight:900}}>
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography>Filtros</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='col-xs-12 row'>
                            <div className='col-xs-12, col-sm-3 mt-2'>
                                <FormControl fullWidth>
                                    <InputLabel id="categoria" className='fuente-page'>Categoría</InputLabel>
                                    <Select
                                        labelId="categoria"
                                        value={this.state.id_categorySelected}
                                        onChange={(e) => {
                                            this.props.findByCategory(e);
                                            this.setState({
                                                id_categorySelected: e.target.value
                                            })
                                        }}
                                        name="categoria"
                                        id="categoria"
                                        input={<OutlinedInput label="Name" />}
                                    >
                                        {categories.length > 0 ?
                                            categories.map((category) => (
                                                <MenuItem key={category.id} value={category.id}>{category.nombre}</MenuItem>
                                            )) : <MenuItem>No hay categorias actualmente</MenuItem>}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-xs-12, col-sm-3 mt-2'>
                                <FormControl fullWidth>
                                    <TextField
                                        type="text"
                                        id="nombre"
                                        name="nombre"
                                        maxRows={3}
                                        onChange={(e) => { this.props.findByName(e) }}
                                        className='fuente-page'
                                        label="Nombre"
                                    />
                                </FormControl>
                            </div>
                            <div className='col-xs-12, col-sm-3 mt-2'>
                                    <Rating style={{ margin: "0px auto" }} name="read-only" value={this.state.rating}
                                        onChange={(event, newValue) => {
                                            this.props.findByRating(newValue);
                                            this.setState({
                                                rating: newValue
                                            });
                                        }} />
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>


            </div>
        )
    }
}
export default Buscador;