import * as React from 'react';
import { Card } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

class CardProduct extends React.Component {
    render() {
        var { id_product, nombre, /*precio,*/ precio_oferta, url, colores, tallas } = this.props.product;
        return (
            <>
                <Card sx={{ maxWidth: 270, minWidth: 270 }} className='m-2' style={{ background: "transparent" }}>
                    <CardMedia
                        sx={{ height: 200 }}
                        image={url}
                        component="img"
                        title={nombre}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            <a href={`/product/${id_product}`} style={{ textDecoration: "none", margin: "0px auto", color: "rgb(124,100,73)", fontFamily: "MyFont", fontWeight: 900 }}>
                                {nombre}
                            </a>
                        </Typography>
                        <div className="col-sm-12 col-md-12 col-xs-12 col-lg-12 row">
                            {colores.map((c, i) => {
                                return <div key={`c${i}`} style={{ background: c.color, width: "15px", height: "15px", margin: "1px", borderRadius: "10px" }}></div>
                            })}
                        </div>
                        <div className="mt-3">
                            {tallas.length > 0 ?
                                <>
                                    <Typography gutterBottom variant="h8" component="div" style={{ textDecoration: "none", margin: "0px auto", color: "rgb(124,100,73)", fontFamily: "MyFont", fontWeight: 900 }}>Tallas</Typography>
                                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-12 row">
                                        {tallas.map((t, i) => {
                                            if (t.enabled === 1) {
                                                return <p key={`t${i}`} style={{ color: "rgb(124,100,73)", fontWeight: 900, fontSize: 15, padding: "2px" }}><strong>{t.talla}</strong></p>
                                            }
                                            return "";
                                        })}
                                    </div>
                                </>
                                : ""}
                        </div>
                    </CardContent>
                    <CardActions>
                        <p style={{ margin: "10px", fontWeight: "500" }}>
                            {/*<span style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid', color: "rgba(0, 0, 0, 0.26)", marginRight: "10px" }}>
                                {`€ ${precio}`}</span>*/}
                                <span style={{ color: "#FA8C16" }}> {`€ ${precio_oferta}`}</span></p>
                    </CardActions>
                </Card>
            </>
        );
    }
}
export default CardProduct;