import React from 'react';
import Box from '@mui/material/Box';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Button } from '@mui/material';

class P1 extends React.Component {

    render() {
        var {t1, t2, IMAGENP1} = this.props;
        return (
            <Box style={{ backgroundImage: `url(${IMAGENP1.imagen})`, height: "900px", backgroundSize: "cover", borderRadius: "10px", marginTop: "30px", marginBottom: "30px" }}>
                <div style={{ margin: "0px auto", color: "white !important", textAlign: "center", position: 'relative', top: "50%"}}>
                    {t1.enabled ?<p className='title-principal' style={{color: t1.color, fontFamily:t1.fuente, fontWeight: t1.grosor, fontSize: t1.size}}>{t1.literal_es} <FavoriteBorderIcon /> </p>:""}
                    {t2.enabled ?<p className='title-principal' style={{color: t2.color, fontFamily:t2.fuente, fontWeight: t2.grosor, fontSize:t2.size}}>{t2.literal_es}</p>:""}
                    <Button href="#contacto" variant="contained" style={{background: "rgb(235,235,235)", color: "black"}}>Contactar</Button>
                </div>
            </Box>
        );
    }
}

export default P1;