import React from 'react';
import { Link, TextField, Button, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, IconButton, FormControl } from '@mui/material';
import Out from '../../error/out';
import Swal from 'sweetalert2'
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';

class Categoria extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            nextId: 0,
            loading: false,
            error: false
        }
        this.delete = this.delete.bind(this);
    }
    componentDidMount() {
        fetch(`${process.env.REACT_APP_API}categories`)
            .then(data => {
                return data.json();
            }).then(data => {
                this.setState({
                    categories: data.result,
                    nextId: Math.max(...data.result.map(p => p.id)) + 1
                });
            }).catch(erro => {
                this.setState({
                    error: true
                })
            })
    }


    delete(e, id) {

        Swal.fire({
            title: '¿ Deseas eliminar esta categoría?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Borrar!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_API}delete/category/${id}`)
                    .then(data => {
                        if (data.status === 200) {
                            Swal.fire({
                                title: 'Borrado',
                                text: 'La categoría se ha eliminado',
                                icon: 'success',
                                timer: 1500,
                                showConfirmButton: false,
                                timerProgressBar: true,
                                willClose: () => {
                                    window.location.reload();
                                }
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Hubo algun error con el servidor',
                            })
                        }
                    });
            }
        })
    }


    render() {
        return (
            <>
                {this.state.loading
                    ? <Out loading={this.state.loading} error={this.state.error} />
                    : <>
                        <form action={`${process.env.REACT_APP_API}category/save`} method="post">
                            <input id="prevPage" name="prevPage" type="hidden" value={window.location.href} />
                            <input id="id" name="id" type="hidden" value={this.state.nextId} />
                            <div style={{ width: "30%", margin: "0px auto", textAlign: "center" }} className="mt-5 row">
                                <FormControl fullWidth className="mt-5">
                                    <TextField
                                        disabled
                                        value={this.state.nextId}
                                        maxRows={3}
                                        label="Pregunta"
                                    />
                                </FormControl>
                                <FormControl fullWidth>

                                    <TextField
                                        className="mt-5"
                                        type="text"
                                        required
                                        id="nombre"
                                        name="nombre"
                                        maxRows={3}
                                        label="Nombre"
                                    />
                                </FormControl>
                                <Button className="mt-2" type="submit" variant="contained">Add</Button>
                            </div>

                        </form>
                        <div style={{ width: "80%", margin: "0px auto", textAlign: "center" }} className="mt-5 mb-5 row">

                            <Link to={`/adm/add/product`} style={{ color: "green" }} component={RouterLink}>
                                <ArrowBackIcon />
                            </Link>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">ID</TableCell>
                                            <TableCell align="left">Nombre</TableCell>
                                            <TableCell align="left"> </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.categories.map((category) => (
                                            <TableRow key={category.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell align="left">{category.id}</TableCell>
                                                <TableCell align="left">{category.nombre}</TableCell>
                                                <TableCell>
                                                    <IconButton aria-label="delete" color="error" onClick={(e) => { this.delete(e, category.id) }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </>}
            </>)
    }
}
export default Categoria;