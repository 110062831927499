import React from 'react';
import { CircularProgress } from '@mui/material';

class Out extends React.Component{
    render(){
        var {loading, error} = this.props;
        return(
            <>
            {error? <div style={{width: "100%", height:"100%", textAlign: "center", lineHeight: "15"}}><img src="https://img.freepik.com/vector-gratis/ups-error-404-ilustracion-concepto-robot-roto_114360-5529.jpg?size=338&ext=jpg&ga=GA1.1.117944100.1709769600&semt=ais" alt="img-error"></img></div>
            :loading?<div style={{width: "100%", height:"100%", textAlign: "center", lineHeight: "15"}}><CircularProgress style={{margin: "0px auto"}} /></div>:""} 
            </>
        );
    }
}

export default Out;