import React from "react";
import { FormControl, TextField } from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ImgSize from '../../../_imagenes/size.png'
import ImgAcc from '../../../_imagenes/accesorios.png'
import ImgColor from '../../../_imagenes/colors.png'

class Lateral extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rating: 0,
            id_categorySelected: this.props.id_categorySelected,
            id_colorSelected: "",
            id_tallaSelected: "",
            openMenuCategories: true,
            openMenuColors: false,
            openMenuSizes: false
        }
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick = (menu) => {
        console.log(this.state.openMenuCategories);
        this.setState({
            [menu]: !this.state[menu]
        })
    };


    render() {
        var { categories, colores, tallas} = this.props;

        return (
            <div className="col-md-12 col-xl-12">
                <aside className="col-sm-12 menu-lateral">
                    <div className="bg-light border rounded-3 p-1 h-100 sticky-top">
                        <ul className="nav nav-pills flex-sm-column flex-row mb-auto justify-content-between text-truncate">
                            <li>
                                <div className='col-xs-12, col-sm-12 mt-2 mb-2'>
                                    <FormControl fullWidth>
                                        <TextField
                                            type="text"
                                            id="nombre"
                                            name="nombre"
                                            maxRows={3}
                                            onChange={(e) => { this.props.findByName(e) }}
                                            className='fuente-page'
                                            label="Nombre"
                                        />
                                    </FormControl>
                                </div>
                            </li>
                            <Divider variant="middle" component="li" />

                            <li className="nav-item col-xs-12, col-sm-12 mt-2 mb-2" >
                                <ListItemButton onClick={e => { this.handleClick("openMenuCategories") }}>
                                    <ListItemIcon>
                                        <img src={ImgAcc} alt="img-size" style={{ width: "2em", height: "2em" }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Categorias" />
                                    {this.state.openMenuCategories ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse style={{ overflowY: "scroll", minHeight: "200px !important", height: "300px" }} in={this.state.openMenuCategories} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {categories.map((category) => {
                                            return (
                                                <ListItemButton key={`C${category.id}}`} sx={{ pl: 4 }}
                                                    value={category.id}
                                                    onClick={(e) => {
                                                        this.setState({ id_categorySelected: category.id })
                                                        this.props.findByCategory(category.id);
                                                    }}>
                                                    <ListItemText className="opciones-buscador-categorias"
                                                        style={{ padding: "10px", borderLeft: +this.state.id_categorySelected === category.id ? "3px solid rgb(207,161,84)" : "none" }}>
                                                        {category.nombre}
                                                    </ListItemText>
                                                </ListItemButton>
                                            )
                                        })}

                                    </List>
                                </Collapse>
                            </li>
                            <Divider variant="middle" component="li" />

                            <li className="nav-item col-xs-12, col-sm-12 mt-2 mb-2">
                                <ListItemButton onClick={e => { this.handleClick("openMenuColors") }}>
                                    <ListItemIcon>
                                        <img src={ImgColor} alt="img-size" style={{ width: "2em", height: "2em" }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Colores" />
                                    {this.state.openMenuColors ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse style={{ overflowY: "scroll", minHeight: "200px !important", height: "300px" }} in={this.state.openMenuColors} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {colores.map((color, i) => {
                                            return (
                                                <ListItemButton key={`Color${i}}`} sx={{ pl: 4 }}
                                                    value={color.id}
                                                    onClick={(e) => {
                                                        this.setState({ id_colorSelected: color.id })
                                                        this.props.findByColor(color.id);
                                                    }}>
                                                    <ListItemText className="opciones-buscador-colores">
                                                        <input
                                                            disabled
                                                            style={{ border: "none", borderLeft: +this.state.id_colorSelected === color.id ? "3px solid rgb(207,161,84)" : "none" }}
                                                            type="color"
                                                            value={color.color}
                                                        />
                                                    </ListItemText>
                                                </ListItemButton>
                                            )
                                        })}

                                    </List>
                                </Collapse>
                            </li>
                            <Divider variant="middle" component="li" />

                            <li className="nav-item col-xs-12, col-sm-12 mt-2 mb-2">
                                <ListItemButton onClick={e => { this.handleClick("openMenuSizes") }}>
                                    <ListItemIcon>
                                        <img src={ImgSize} alt="img-size" style={{ width: "2em", height: "2em" }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Tallas" />
                                    {this.state.openMenuSizes ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse style={{ overflowY: "scroll", minHeight: "200px !important", height: "300px" }} in={this.state.openMenuSizes} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {tallas.map((talla, i) => {
                                            return (
                                                <ListItemButton key={`talla${i}}`} sx={{ pl: 4 }}
                                                    value={talla}
                                                    onClick={(e) => {
                                                        this.setState({ id_tallaSelected: talla.id })
                                                        this.props.findByTallas(talla.id);
                                                    }}>
                                                    <ListItemText className="opciones-buscador-tallas"
                                                        style={{ padding: "10px", borderLeft: +this.state.id_tallaSelected === talla.id ? "3px solid rgb(207,161,84)" : "none" }}>
                                                        {talla.talla}
                                                    </ListItemText>
                                                </ListItemButton>
                                            )
                                        })}

                                    </List>
                                </Collapse>
                            </li>
                        </ul>
                    </div>
                </aside>
            </div>
        );
    }
}
export default Lateral;