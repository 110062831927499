import React from 'react';
import {
    Routes,
    Route,
    BrowserRouter as Router,
} from "react-router-dom";

import Products from './web/products/products';
import Detail from './web/products/detail';
import ADMProducts from  '../components/admin/product/products'
import Categoria from './admin/categoria/categoria';
import Imagen from './admin/imagenes/imagen';
import Out from './error/out';
import PaginaPrincipal from './pages/pagePrincipal';
import Literales from './admin/literales/literales';
import ADMColor from './admin/color/color';
import ADMProductColors from './admin/color/productColors';
import ADMTalla from './admin/tallas/tallas';
import ADMProductTallas from './admin/tallas/productTallas';
import Collections from './web/collections/collections';
import AdmCollections from '../components/admin/collection/collection';
const Rutas = () => {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<PaginaPrincipal />} />
                <Route exact path="/products" element={<Products />} />
                <Route exact path="/colecciones" element={<Collections />} />
                <Route exact path="/adm/colecciones" element={<AdmCollections />} />
                <Route exact path="/product/:id" element={<Detail />} />
                <Route exact path="/adm/products" element={<ADMProducts />} />
                <Route exact path="/adm/colores" element={<ADMColor />} />
                <Route exact path="/adm/tallas" element={<ADMTalla />} />
                <Route exact path="/adm/add/category" element={<Categoria />} />
                <Route exact path="/adm/literales" element={<Literales />} />
                <Route exact path="/adm/product/:id/imagenes" element={<Imagen />} />
                <Route exact path="/adm/product/:id/colores" element={<ADMProductColors />} />
                <Route exact path="/adm/product/:id/tallas" element={<ADMProductTallas />} />
                <Route exact path="*" element={<Out error={true} />} />
            </Routes>
        </Router>
    );
}

export default Rutas