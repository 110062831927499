import React from "react";
import { Box, Link, Button, FormControl, InputLabel, Select, MenuItem, OutlinedInput, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Out from '../../error/out';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2'
import axios from 'axios';

class ProductColores extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_productSelected: window.location.pathname.split("/")[3],
            product_colors: [],
            colores: [],
            colorSelected: [],
            openDialog: false,
            loading: true,
        }
        this.modal = this.modal.bind(this);
    }

    closeDialog = () => {
        this.setState({
            openDialog: false
        })
    }

    newColor = () => {
        this.setState({
            openDialog: true,
        })
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API}colores/${this.state.id_productSelected}`)
            .then(data => {
                return data.json();
            }).then(data => {
                this.setState({
                    product_colors: data.result,
                    loading:false
                });
            }).catch(erro => {
                this.setState({
                    error: true
                })
            })

        fetch(`${process.env.REACT_APP_API}colores`)
            .then(data => {
                return data.json();
            }).then(data => {
                this.setState({
                    colores: data.result,
                    loading:false
                });
            }).catch(erro => {
                this.setState({
                    error: true
                })
            })
    }

    save = async () => {
        var objAEnviar = {};
        Object.keys(this.state).map(k => {
            if (k.includes("Selected")) {
                var aux = k.substring(0, k.indexOf("S"));
                objAEnviar[aux] = this.state[k]
            }
            return "";
        })
        const requestColorProduct = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(objAEnviar)
        };

        fetch(`${process.env.REACT_APP_API}colores/product/save`, requestColorProduct)
            .then(data => {
                return data.json();
            }).then(data => {
                if (data.code === 201) window.location.reload();
                if (data.code === 202) console.log(data);
            })
            .catch(erro => {
                this.setState({
                    error: true
                })
            })
    }

    modal = () => {
        return (
            <Dialog
                maxWidth="md"
                open={this.state.openDialog}
                onClose={this.closeDialog}
            >
                <DialogTitle id="responsive-dialog-title">
                    <Alert icon={false} severity="success">
                        <h3>{`Nuevo color para el producto ${this.state.id_productSelected}`}</h3>
                    </Alert>
                </DialogTitle>
                <DialogContent>
                    <Box style={{ color: "#4f4f4f", fontWeight: "900", background: "rgb(235,235,235)", backgroundSize: "cover", borderRadius: "10px", marginTop: "30px", marginBottom: "30px", padding: "30px" }}>
                        <div style={{ margin: "0px auto", textAlign: "center" }} className="row">
                            <div className="mt-5 col-sm-12 col-xs-12 col-md-12 row">

                                <div className="col-sm-4 col-xs-6 col-md-4">
                                    <label htmlFor="literal_es" className="form-label">Producto</label>
                                    <input className="form-control" id="id" name="id"
                                        required
                                        disabled
                                        value={this.state.id_productSelected}
                                    />
                                </div>

                                <div className="col-md-4 col-xs-6 mt-3">

                                    <FormControl fullWidth style={{ background: "white" }}>
                                        <InputLabel id="in_stock">Color</InputLabel>
                                        <Select
                                            labelId="in_stock"
                                            value={this.state.colorSelected}
                                            onChange={(e) => {
                                                this.setState({
                                                    colorSelected: e.target.value
                                                })
                                            }}
                                            name="color"
                                            id="color"
                                            input={<OutlinedInput label="Name" />}
                                        >
                                            {this.state.colores.map(c => {
                                                return <MenuItem key={c.id} value={c.id}><input type="color" disabled value={c.color} /></MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="col-sm-12 col-xs-12 col-md-12" style={{ textAlign: "left" }}>
                                <Button className="mt-3" type="buttom" variant="contained" onClick={this.save}>Añadir color</Button>
                            </div>
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    delete(id) {
        Swal.fire({
            title: '¿ Deseas eliminar este color del producto?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Borrar!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_API}delete/product/color/${id}`)
                    .then(data => {
                        if (data.status === 200) {
                            Swal.fire({
                                title: 'Borrado',
                                text: 'El color se ha eliminado',
                                icon: 'success',
                                timer: 1500,
                                showConfirmButton: false,
                                timerProgressBar: true,
                                willClose: () => {
                                    window.location.reload();
                                }
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Hubo algun error con el servidor',
                            })
                        }
                    });
            }
        })
    }

    renderTabla = () => {
        return (
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">ID</TableCell>
                            <TableCell align="left">Color</TableCell>
                            <TableCell align="left" colSpan={2}> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.product_colors.map((cp) => (
                            <TableRow key={cp.cp_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left">{cp.cp_id}</TableCell>
                                <TableCell align="left"><input type="color" value={cp.color} disabled /></TableCell>
                                <TableCell>
                                    <IconButton aria-label="delete" color="error" onClick={(e) => { this.delete(cp.cp_id) }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    render() {
        return (
            <>
                {this.state.loading
                    ? <Out loading={this.state.loading} error={this.state.error} />
                    : <>
                        {this.modal()}
                        <div style={{ width: "50%", margin: "0px auto" }} className="mt-5 mb-5 row">
                            <div className='col-sm-12 col-md-12 col-xs-12 mb-5 row'>
                                <Button variant="contained" onClick={(e) => { this.newColor() }}>
                                    Nuevo color
                                </Button>
                            </div>
                            <div className="col-sm-12 col-md-12 col-xs-12 mb-5 row">
                                <Link to={`/adm/products`} style={{ color: "green" }} component={RouterLink}>
                                    <ArrowBackIcon />
                                </Link>
                            </div>
                            {this.renderTabla()}
                        </div>
                    </>}
            </>)
    }
}

export default ProductColores;