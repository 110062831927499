import './App.css';
import React from 'react';
import Footer from './components/comun/footer';
import Rutas from './components/rutas';
import BtnWhatsApp from './components/comun/btnWhatsApp';
import AppBar from './components/comun/appbar/appbar';

function App() {
    return (
        <React.Fragment >
            <AppBar />
            <div style={{ width: "90%", margin: "0px auto", marginTop: "120px", marginBottom: "100px" }}>
                <BtnWhatsApp />
                <Rutas />
            </div>
            <Footer />
        </React.Fragment>
    );
}

export default App;
