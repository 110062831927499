
import { Accordion, AccordionSummary, AccordionDetails, CardMedia } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axios from "axios";
import "./collection.css";

export default function Collections() {

    const [loading, setLoading] = useState(false);
    const [collections, setCollections] = useState([]);
    const [products, setProducts] = useState([]);

    async function getCollections() {
        return await axios.get(`${process.env.REACT_APP_API}collections`)
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function getProducts() {
        return await axios.get(`${process.env.REACT_APP_API}products`)
            .then(response => {
                return response.data.result;
            }).catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        setLoading(true);
        (async () => {
            const collections = await getCollections();
            const products = await getProducts();
            setProducts(products);
            setCollections(collections);
            setLoading(false);
        })()
    }, [])

    return (Acordion(collections))

    function getProductsByCollections(idCollection) {
        let filter = products.filter(p => p.id_collection === idCollection);
        return filter;
    }


    function Acordion(collections) {
        if (loading === false) {
            if (collections !== undefined && collections.length > 0) {
                return (
                    <div className='collection-acordion'>
                        {collections.map((c,i) => {
                            return (
                                <Accordion key={c.id} style={{background: c.color}} defaultExpanded={i === 0 ? true : false}    >
                                    <AccordionSummary style={{color: "white", fontWeight: 900}}
                                        expandIcon={<ExpandMoreIcon style={{color:"white"}}/>}
                                        aria-controls={`panel${c.id}`}
                                        id={`panel${c.id}`}
                                    >
                                        {c.nombre}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='galery-collection'>
                                            {getProductsByCollections(c.id).map((p,i) => {
                                                return (
                                                    <CardMedia
                                                        key={`collection${p.id}`}
                                                        sx={{ height: 200 }}
                                                        image={p.url}
                                                        component="img"
                                                        title={p.nombre}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    </div>)
            } else {
                return (<h3>No hay colecciones en este momento</h3>)
            }
        } else {
            return CircularIndeterminate();
        }
    }
}

function CircularIndeterminate() {
    return (
        <Box style={{ textAlign: "center" }}>
            <CircularProgress />
        </Box>
    );
}