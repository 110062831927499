import React from 'react';
import { Box, Checkbox, Link, Button, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, IconButton, FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material';
import Out from '../../error/out';
import Swal from 'sweetalert2'
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import Rating from '@mui/material/Rating';
import { Link as RouterLink } from 'react-router-dom';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Buscador from '../../web/buscador/buscador';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Tooltip from '@mui/material/Tooltip';
import TablePagination from '@mui/material/TablePagination';
import PaletteIcon from '@mui/icons-material/Palette';
import FormatSizeIcon from '@mui/icons-material/FormatSize';


class Products extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            btnAddOrUpdate: "Añadir",
            title: "Nuevo producto",
            totalProducts: [],
            products: [],
            nextId: 0,
            loading: true,
            error: false,
            categories: [],
            id: 0,
            nombre: "",
            descripcion: "",
            precio: 0,
            precio_oferta: 0,
            rating: 0,
            categoria: 1,
            img_principal: "",
            is_new: "",
            in_stock: "",
            openDialog: false,
            imagenes: [],
            enabledAcordionImagenes: false,
            page: 0,
            rowsPerPage: 10,
            id_collection: "",
            collections: []


        }
        this.delete = this.delete.bind(this);
        this.changeVisible = this.changeVisible.bind(this);
        this.findByCategory = this.findByCategory.bind(this);
        this.findByName = this.findByName.bind(this);
        this.findByRating = this.findByRating.bind(this);
        this.deleteImagen = this.deleteImagen.bind(this);
        this.getCollectionName = this.getCollectionName.bind(this);
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        })
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: +event.target.value,
            page: 0
        })
    };


    getCollectionName(id) {
        let a = this.state.collections.filter(c => c.id === id)[0];
        return a=== undefined? "":a.nombre;
    }

    componentDidMount() {



        fetch(`${process.env.REACT_APP_API}all/products`)
            .then(data => {
                return data.json();
            }).then(data => {
                var proximoId = data.result.length !== 0 ? Math.max(...data.result.map(p => p.id_product)) + 1 : 0;
                this.setState({
                    totalProducts: data.result,
                    products: data.result,
                    nextId: proximoId,
                    id: proximoId,
                    loading: false
                });
            }).catch(erro => {
                this.setState({
                    error: true
                })
            })

        fetch(`${process.env.REACT_APP_API}categories`)
            .then(data => {
                return data.json();
            }).then(data => {
                this.setState({
                    categories: data.result,
                    loading: false
                });
            }).catch(erro => {
                this.setState({
                    error: true
                })
            })
        fetch(`${process.env.REACT_APP_API}collections`)
            .then(data => {
                return data.json();
            }).then(data => {
                this.setState({
                    collections: data.result,
                    loading: false
                });
            }).catch(erro => {
                this.setState({
                    error: true
                })
            })
    }

    delete(e, id) {
        Swal.fire({
            title: '¿ Deseas eliminar este producto?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Borrar!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_API}delete/product/${id}`)
                    .then(data => {
                        if (data.status === 200) {
                            Swal.fire({
                                title: 'Borrado',
                                text: 'El producto se ha eliminado',
                                icon: 'success',
                                timer: 1500,
                                showConfirmButton: false,
                                timerProgressBar: true,
                                willClose: () => {
                                    window.location.reload();
                                }
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Hubo algun error con el servidor',
                            })
                        }
                    });
            }
        })
    }

    changeVisible = (id, value) => {
        var visible = value === false ? 0 : 1;
        fetch(`${process.env.REACT_APP_API}product/visible/${visible}/${id}`)
            .then(data => {
                return data.json();
            }).then(data => {
                window.location.reload();
            }).catch(erro => {
                this.setState({
                    error: true
                })
            })
    }

    inStock = (id, value) => {
        var in_stock = value === false ? 0 : 1;
        fetch(`${process.env.REACT_APP_API}product/instock/${in_stock}/${id}`)
            .then(data => {
                return data.json();
            }).then(data => {
                window.location.reload();
            }).catch(erro => {
                this.setState({
                    error: true
                })
            })
    }

    isNew = (id, value) => {
        var is_new = value === false ? 0 : 1;
        fetch(`${process.env.REACT_APP_API}product/isnew/${is_new}/${id}`)
            .then(data => {
                return data.json();
            }).then(data => {
                window.location.reload();
            }).catch(erro => {
                this.setState({
                    error: true
                })
            })
    }

    selectRow = (e) => {
        this.setState({
            openDialog: true
        })

        var productSelected = this.state.products.filter(p => p.id_product === e)[0];
        fetch(`${process.env.REACT_APP_API}imagenes/${productSelected.id_product}`)
            .then(data => {
                return data.json();
            }).then(data => {
                this.setState({
                    imagenes: data.result,
                    enabledAcordionImagenes: true
                })
            }).catch(erro => {
                this.setState({
                    error: true
                })
            })
        this.setState({
            btnAddOrUpdate: "Actualizar producto",
            title: `Actualizando el producto: ${productSelected.nombre}`,
            id: productSelected.id_product,
            nombre: productSelected.nombre,
            descripcion: productSelected.descripcion !== null ? productSelected.descripcion : "",
            precio: productSelected.precio,
            precio_oferta: productSelected.precio_oferta,
            rating: productSelected.rating,
            categoria: +productSelected.id_categoria,
            is_new: productSelected.is_new,
            in_stock: productSelected.in_stock,
            img_principal: productSelected.url,
            id_collection: productSelected.id_collection
        })
    }

    save = async () => {
        const requestOptionsAnho = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: this.state.id,
                nombre: this.state.nombre,
                descripcion: this.state.descripcion,
                precio: this.state.precio,
                precio_oferta: this.state.precio_oferta,
                rating: this.state.rating,
                categoria: this.state.categoria,
                is_new: this.state.is_new,
                in_stock: this.state.in_stock,
                isEdit: this.state.nextId !== this.state.id,
                id_collection: this.state.id_collection
            })
        };
        fetch(`${process.env.REACT_APP_API}product/save`, requestOptionsAnho)
            .then(data => {
                return data.json();
            }).then(data => {
                if (data.code === 201) window.location.reload();
                if (data.code === 202) console.log(data);
            })
            .catch(erro => {
                this.setState({
                    error: true
                })
            })
    }

    findByCategory = (e) => {
        var id = +e.target.value;
        this.setState({ products: id === 1 ? this.state.totalProducts : this.state.totalProducts.filter(product => +product.id_categoria === id) })
    }

    findByName = (e) => {
        var nombre = e.target.value;
        this.setState({ products: this.state.totalProducts.filter(product => product.nombre.includes(nombre)) })
    }

    findByRating = (e) => {
        this.setState({ products: this.state.totalProducts.filter(product => +product.rating === +e) })
    }

    openDialog = () => {
        this.setState({
            title: "Nuevo producto",
            id: this.state.nextId,
            nombre: "",
            descripcion: " ",
            precio: 0,
            precio_oferta: 0,
            rating: 0,
            categoria: 1,
            is_new: 0,
            in_stock: 0,
            imagenes: [],
            img_principal: "",
            id_collection: ""
        })

        this.setState({
            openDialog: true
        })
    };

    closeDialog = () => {
        this.setState({
            openDialog: false
        })
    };

    deleteImagen(id) {

        Swal.fire({
            title: '¿ Deseas eliminar esta imagen?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Borrar!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_API}delete/imagen/${id}`)
                    .then(data => {
                        if (data.status === 200) {
                            Swal.fire({
                                title: 'Borrado',
                                text: 'La imagen se ha eliminado',
                                icon: 'success',
                                timer: 1500,
                                showConfirmButton: false,
                                timerProgressBar: true,
                                willClose: () => {
                                    this.setState({
                                        imagenes: this.state.imagenes.filter(p => p.id !== id)
                                    })
                                }
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Hubo algun error con el servidor',
                            })
                        }
                    });
            }
        })
    }


    cargarImagen = (e) => {
        this.setState({
            img_principal: e.target.files[0]
        })
        var input = document.getElementById("img_principal");
        var fReader = new FileReader();
        fReader.readAsDataURL(input.files[0]);
        fReader.onloadend = function (event) {
            var aux = document.getElementById("div-imagenes").getElementsByTagName("img");
            if (aux.length > 0) {
                aux[0].parentNode.removeChild(aux[0]);
            }
            var div = document.createElement("div");
            var itemAClonaraux = document.getElementById("itemAClonar");
            var button = itemAClonaraux.cloneNode(true);
            button.setAttribute('class', 'btn-eliminar MuiButtonBase-root MuiIconButton-root MuiIconButton-colorError MuiIconButton-sizeMedium css-1jo1f9u-MuiButtonBase-root-MuiIconButton-root')
            button.onclick = function () {
                this.parentNode.remove();

            }
            div.appendChild(button);
            var oImg = document.createElement("img");
            oImg.setAttribute('src', event.target.result);
            oImg.setAttribute('alt', 'foto-a-subir');
            oImg.setAttribute('id', 'foto-a-subir');
            oImg.setAttribute('height', '200px');
            oImg.setAttribute('width', '200px');
            div.appendChild(oImg);
            var div_imagenes = document.getElementById("div-imagenes");
            div_imagenes.appendChild(div);
        }
    }

    render() {
        return (
            <>
                <React.Fragment>
                    <div style={{ display: "none" }}>
                        <IconButton aria-label="delete" id="itemAClonar" color="error">
                            <DeleteIcon />
                        </IconButton>
                    </div>
                    <div className='col-sm-12 col-md-12 col-xs-12' style={{ marginTop: "30px !important" }}>
                        <Button variant="contained" className='ml-2 mt-3 title-aux' onClick={(e) => { this.openDialog(false) }}>
                            Nuevo Producto
                        </Button>

                        <Button href="/adm/add/category" color="secondary" variant="contained" className='ml-2 mt-3 title-aux'>
                            Nueva categoría
                        </Button>

                        <Button href="/adm/literales" color="secondary" variant="contained" className='ml-2 mt-3 title-aux'>
                            Nuevo literal
                        </Button>

                        <Button href="/adm/colores" color="secondary" variant="contained" className='ml-2 mt-3 title-aux'>
                            Nuevo color
                        </Button>

                        <Button href="/adm/tallas" color="secondary" variant="contained" className='ml-2 mt-3 title-aux'>
                            Nueva talla
                        </Button>
                        <Button href="/adm/colecciones" color="secondary" variant="contained" className='ml-2 mt-3 title-aux'>
                            Nueva colección
                        </Button>
                    </div>

                    <Dialog
                        fullScreen
                        open={this.state.openDialog}
                        onClose={this.closeDialog}
                    >
                        <DialogTitle id="responsive-dialog-title">
                            {this.state.title}
                        </DialogTitle>

                        <DialogContent>

                            <Box style={{ color: "#4f4f4f", fontWeight: "900", background: "rgb(235,235,235)", backgroundSize: "cover", borderRadius: "10px", marginTop: "30px", marginBottom: "30px", padding: "30px" }}>
                                <div className="row col-md-12">
                                    <div className=" col-xs-12 col-md-4 mr-2">
                                        <label htmlFor="id" className="form-label">Siguiente id libre</label>
                                        <input disabled type="text" className="form-control" id="id" name="id" value={this.state.id} />
                                    </div>

                                    <div className="col-xs-12 col-md-4">
                                        <label htmlFor="nombre" className="form-label">Nombre</label>
                                        <input type="text" className="form-control"
                                            required
                                            onChange={(e) => {
                                                this.setState({ nombre: e.target.value });
                                            }}
                                            value={this.state.nombre} id="nombre" name="nombre" />
                                    </div>
                                </div>

                                <div className="row col-md-12 col-xs-12">
                                    <div className="mb-3 col-md-12">
                                        <label htmlFor="descripcion" className="form-label">Descripción</label>
                                        <textarea className="form-control" id="descripcion" name="descripcion" rows="3"
                                            value={this.state.descripcion}
                                            required
                                            onChange={(e) => {
                                                this.setState({ descripcion: e.target.value });
                                            }}></textarea>
                                    </div>
                                </div>

                                <div className="row col-md-12 col-xs-12">
                                    <div className="input-group mb-3 col-md-4 col-xs-12">
                                        <span className="input-group-text">€</span>
                                        <input type="number" min="0.00" max="10000.00" step="0.01" id="precio" name="precio" className="form-control" placeholder='Precio Real'
                                            value={this.state.precio}
                                            required
                                            onChange={(e) => {
                                                this.setState({ precio: e.target.value });
                                            }}
                                        />
                                    </div>
                                    <div className="input-group mb-3 col-md-4 col-xs-12">
                                        <span className="input-group-text">€</span>
                                        <input type="number" min="0.00" max="10000.00" step="0.01" id="precio_oferta" name="precio_oferta" className="form-control" placeholder='Precio oferta'
                                            value={this.state.precio_oferta}
                                            required
                                            onChange={(e) => {
                                                this.setState({ precio_oferta: e.target.value });
                                            }}
                                        />
                                    </div>
                                    <div className='col-md-4 col-xs-12'>
                                        <Rating
                                            id="rating"
                                            name="rating"
                                            value={this.state.rating}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    rating: newValue
                                                });
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='row col-md-12 col-xs-12'>
                                    <div className="col-md-3 col-xs-12 mt-2">
                                        <FormControl style={{ background: "white" }} fullWidth>

                                            <Select
                                                labelId="categoria"
                                                value={this.state.categoria}
                                                onChange={(e) => {
                                                    this.setState({
                                                        categoria: e.target.value
                                                    })
                                                }}
                                                name="categoria"
                                                id="categoria"
                                                input={<OutlinedInput label="Name" />}
                                            >
                                                {this.state.categories.map((category) => (
                                                    <MenuItem key={category.id} value={category.id}>{category.nombre}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <Link to={`/adm/add/category`} style={{ color: "green" }} component={RouterLink}>
                                            <PlaylistAddIcon />
                                        </Link>

                                    </div>
                                    <div className="col-md-3 col-xs-6 mt-2">
                                        <FormControl fullWidth style={{ background: "white" }}>
                                            <InputLabel id="is_new">¿ Marcar como nuevo ?</InputLabel>
                                            <Select
                                                labelId="is_new"
                                                value={this.state.is_new}
                                                onChange={(e) => {
                                                    this.setState({
                                                        is_new: e.target.value
                                                    })
                                                }}
                                                name="is_new"
                                                id="is_new"
                                                input={<OutlinedInput label="Name" />}
                                            >
                                                <MenuItem key={1} value={1}>Si</MenuItem>
                                                <MenuItem key={0} value={0}>No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-3 col-xs-6 mt-2">

                                        <FormControl fullWidth style={{ background: "white" }}>
                                            <InputLabel id="in_stock">¿ Marcar que hay stock ?</InputLabel>
                                            <Select
                                                labelId="in_stock"
                                                value={this.state.in_stock}
                                                onChange={(e) => {
                                                    this.setState({
                                                        in_stock: e.target.value
                                                    })
                                                }}
                                                name="in_stock"
                                                id="in_stock"
                                                input={<OutlinedInput label="Name" />}
                                            >
                                                <MenuItem key={1} value={1}>Si</MenuItem>
                                                <MenuItem key={0} value={0}>No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className="col-md-3 col-xs-6 mt-2">

                                        <FormControl fullWidth style={{ background: "white" }}>
                                            <InputLabel id="id_collection">Colección</InputLabel>
                                            <Select
                                                labelId="id_collection"
                                                value={this.state.id_collection}
                                                onChange={(e) => {
                                                    this.setState({
                                                        id_collection: e.target.value
                                                    })
                                                }}
                                                name="id_collection"
                                                id="id_collection"
                                                input={<OutlinedInput label="Name" />}
                                            >
                                                {this.state.collections.map((collection) => (
                                                    <MenuItem key={collection.id} value={collection.id}>{collection.nombre}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='row col-md-12 col-xs-12 mt-2'>
                                    <img src={this.state.img_principal} width={150} height={150} alt={1}></img>
                                </div>
                                <Tooltip title="Añadir imagenes al producto">
                                    <Link to={`/adm/product/${this.state.id}/imagenes`} color="success" underline="hover" component={RouterLink}>
                                        <AddPhotoAlternateIcon />
                                    </Link>
                                </Tooltip>
                                { /*this.state.enabledAcordionImagenes === true && this.state.imagenes.length > 0 ? <Acordion imagenes={this.state.imagenes} idProduct={this.state.id} delete={this.deleteImagen} /> : ""*/}
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={this.save}>
                                {this.state.btnAddOrUpdate}
                            </Button>
                            <Button variant="contained" onClick={this.closeDialog} color="error">
                                Cancelar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
                {this.state.loading
                    ? <Out loading={this.state.loading} error={this.state.error} />
                    : <>
                        <div style={{ width: "100%", margin: "0px auto", textAlign: "center" }} className="mt-5 mb-5 row">
                            <div className='col-sm-12 col-xs-12 col-md-12 mb-3'>
                                <Buscador categories={this.state.categories} findByCategory={this.findByCategory} findByName={this.findByName} findByRating={this.findByRating} />
                            </div>
                            <Paper sx={{ width: '100%' }}>
                                <TableContainer className="Mui-selected" component={Paper}>
                                    <Table id="table_products" size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">ID</TableCell>
                                                <TableCell align="left">Nombre</TableCell>
                                                <TableCell align="left">Descripción</TableCell>
                                                <TableCell align="left">Precio "real"</TableCell>
                                                <TableCell align="left">Precio Oferta</TableCell>
                                                <TableCell align="left">Num. Estrellas</TableCell>
                                                <TableCell align="left">Categoria</TableCell>
                                                <TableCell align="left">Colección</TableCell>
                                                <TableCell align="left">Imagen principal</TableCell>
                                                <TableCell align="left">¿ Es nuevo ?</TableCell>
                                                <TableCell align="left">¿ Hay stock ? </TableCell>
                                                <TableCell align="left">¿ Visible al publico? </TableCell>
                                                <TableCell align="left" colSpan={6}> </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.products.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((producto, i) => (
                                                <TableRow id={producto.id_product} key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="left">{producto.id_product}</TableCell>
                                                    <TableCell align="left">{producto.nombre}</TableCell>
                                                    <TableCell align="left">{producto.descripcion}</TableCell>
                                                    <TableCell align="left">{producto.precio}</TableCell>
                                                    <TableCell align="left">{producto.precio_oferta}</TableCell>
                                                    <TableCell align="left">
                                                        <Rating style={{ margin: "0px auto" }} name="read-only" value={producto.rating} readOnly />
                                                    </TableCell>
                                                    <TableCell align="left">{producto.id_categoria}</TableCell>
                                                    <TableCell align="left">{this.getCollectionName(producto.id_collection)}</TableCell>
                                                    <TableCell align="left"><img src={producto.url} alt={producto.id_product} width={70} height={70}></img></TableCell>
                                                    <TableCell align="left">
                                                        <Checkbox checked={producto.is_new === 1 ? true : false} onChange={(e, v) => { this.isNew(producto.id_product, v) }} />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Checkbox checked={producto.in_stock === 1 ? true : false} onChange={(e, v) => { this.inStock(producto.id_product, v) }} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Checkbox checked={producto.visible === 1 ? true : false} onChange={(e, v) => { this.changeVisible(producto.id_product, v) }} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Tooltip title="Eliminar producto">
                                                            <IconButton aria-label="delete" color="error" onClick={(e) => { this.delete(e, producto.id_product) }}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Tooltip title="Añadir imagenes al producto">
                                                            <Link to={`/adm/product/${producto.id_product}/imagenes`} color="success" underline="hover" component={RouterLink}>
                                                                <AddPhotoAlternateIcon />
                                                            </Link>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Tooltip title="Ver detalle del producto">
                                                            <ArrowCircleRightIcon color="success" onClick={() => this.selectRow(producto.id_product)} style={{ cursor: "pointer" }} />
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Link to={`/adm/product/${producto.id_product}/colores`} color="success" underline="hover" component={RouterLink}>
                                                            <PaletteIcon />
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Link to={`/adm/product/${producto.id_product}/tallas`} color="success" underline="hover" component={RouterLink}>
                                                            <FormatSizeIcon />
                                                        </Link>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={this.state.products.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                />
                            </Paper>
                        </div>
                    </>}
            </>)
    }
}

export default Products