import React from 'react'
import { Card, Link } from '@mui/material';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import Out from '../../error/out';
import { Tag } from 'primereact/tag';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import ImageZoom from "react-image-zooom";

class Detail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            product: "",
            id_productSelected: window.location.pathname.split("/")[2],
            error: false,
            imagenes: [],
            open: false,
            imagen: "",
            colores: [],
            tallas: []
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
    }
    componentDidMount() {
        fetch(`${process.env.REACT_APP_API}product/${this.state.id_productSelected}`)
            .then(data => {
                return data.json();
            }).then(data => {
                this.setState({
                    product: data.result[0],
                    loading: false,
                });
                fetch(`${process.env.REACT_APP_API}imagenes/${this.state.id_productSelected}`)
                    .then(data => {
                        return data.json();
                    }).then(data => {
                        this.setState({
                            imagenes: data.result.length === 0
                                ? this.state.imagenes.push("https://static.vecteezy.com/system/resources/previews/004/141/669/original/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg")
                                : data.result,
                            loading: false,
                        });

                    }).catch(err => {
                        this.setState({
                            error: true
                        })
                    })
                fetch(`${process.env.REACT_APP_API}colores/${this.state.id_productSelected}`)
                    .then(data => {
                        return data.json();
                    }).then(data => {
                        this.setState({
                            colores: data.result
                        });

                    }).catch(err => {
                        this.setState({
                            error: true
                        })
                    })

                fetch(`${process.env.REACT_APP_API}tallas/${this.state.id_productSelected}`)
                    .then(data => {
                        return data.json();
                    }).then(data => {
                        this.setState({
                            tallas: data.result
                        });

                    }).catch(err => {
                        this.setState({
                            error: true
                        })
                    })
            }).catch(err => {
                this.setState({
                    error: true
                })
            })
    }

    handleOpen = () => {
        this.setState({ open: true })
    }

    handleClose = () => {
        this.setState({ open: false })
    }



    render() {
        return (
            <>
                {this.state.product === "" ? <Out loading={this.state.loading} error={this.state.error} />
                    : <>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/products"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Productos
                            </Link>
                            <Typography color="text.primary">{this.state.product.nombre}</Typography>
                        </Breadcrumbs>

                        <div style={{ width: "80%", margin: "0px auto", marginBottom: "30px", marginTop: "20px", background: "transparent" }}>
                            {this.state.product.is_new ? <Tag severity="danger" value="NEW" className='mr-2' /> : ""}
                            {this.state.product.in_stock ? <Tag severity="success" value="INSTOCK" /> : <Tag severity="warning" value="NO STOCK" />}
                        </div>

                        <Card style={{ margin: "0px auto", width: "80%", background: "transparent", textAlign: "center" }} >


                            {this.state.imagenes.length > 0 ?
                                <div className='col-sm-8 col-md-8 col-xs-12 mt-5' style={{ margin: "0px auto" }}>
                                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                                        <ol class="carousel-indicators">
                                            {this.state.imagenes.map((m, i) => {
                                                return i === 1 ? <li data-target="#carouselExampleIndicators" data-slide-to={i} class="active"></li>
                                                    : <li data-target="#carouselExampleIndicators" data-slide-to={i}></li>
                                            })}
                                        </ol>
                                        <div class="carousel-inner">
                                            {this.state.imagenes.map((m, i) => {
                                                var claseAAplicar = i === 1 ? "carousel-item active" : "carousel-item";
                                                return (
                                                    <div class={claseAAplicar}>
                                                        <ImageZoom src={m.url} class="d-block w-100" alt="Second slide" />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span class="sr-only" style={{color:"black"}}>Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span class="sr-only"style={{color:"black"}}>Next</span>
                                        </a>
                                    </div>
                                </div>
                                : <h3 style={{ textAlign: "center", fontFamily: "MyFont", color: "rgb(124,100,73)", fontWeight: 900, margin: "0px auto", marginTop: "20px" }}>No hay imagenes actuales</h3>}
                            <CardContent style={{ textAlign: "center" }}>

                                <Typography gutterBottom variant="h5" component="div" style={{ color: "rgb(124,100,73)", fontFamily: "MyFont", fontWeight: 900 }}>
                                    {this.state.product.nombre}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" style={{ color: "rgb(124,100,73)", fontFamily: "MyFont", fontWeight: 900 }}>
                                    {this.state.product.descripcion}
                                </Typography>
                                <div class="row" style={{ margin: "0px auto", width: "fit-content" }}>
                                    {this.state.colores.map((c, i) => {
                                        return <div key={`c${i}`} style={{ background: c.color, width: "15px", height: "15px", margin: "1px", borderRadius: "10px" }}></div>
                                    })}
                                </div>
                                <div className="mt-3">
                                    {this.state.tallas.length > 0 ?
                                        <>
                                            <Typography key="titleTalla" gutterBottom variant="h8" component="div" style={{ textDecoration: "none", margin: "0px auto", color: "rgb(124,100,73)", fontFamily: "MyFont", fontWeight: 900 }}>Tallas</Typography>
                                            <div className="row" style={{ margin: "0px auto", width: "fit-content" }}>
                                                {this.state.tallas.map((t, i) => {
                                                    if (t.enabled) {
                                                        return <p key={`t${i}`} style={{ color: "rgb(124,100,73)", fontWeight: 900, fontSize: 15, padding: "2px" }}><strong>{t.talla}</strong></p>
                                                    }
                                                    return "";
                                                })}
                                            </div>
                                        </>
                                        : ""}
                                </div>
                                <div className='col-sm-12 col-xs-12 col-md-12 col-lg-12'>
                                    <Rating style={{ margin: "0px auto" }} name="read-only" value={this.state.product.rating} readOnly />
                                </div>
                                <div className='col-sm-12 col-xs-12 col-md-12 col-lg-12'>
                                    <p style={{ margin: "10px", fontWeight: "500", fontSize: "20px" }}>
                                        {/*<span style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid', color: "rgba(0, 0, 0, 0.26)", marginRight: "10px" }}>
                                            {`€ ${this.state.product.precio}`}</span>*/}
                                            <span style={{ color: "#FA8C16" }}> {`€ ${this.state.product.precio_oferta}`}</span></p>
                                </div>
                            </CardContent>
                        </Card>

                    </>
                }
            </>
        )
    }
}

export default Detail;